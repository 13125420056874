import { AgendaDiariaVisitanteModule } from './agenda-diaria-visitante/agenda-diaria-visitante.module';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/services/auth.guard';
import { EnqueteGuard } from './core/guards/enquete-guard';
import { ExternalGuard } from './auth/services/external.guard';


const routes: Routes = [

  {
    path: 'users',
    loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'roles',
    loadChildren: () => import('./roles/roles.module').then(m => m.RolesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'filas',
    loadChildren: () => import('./filas/filas.module').then(m => m.FilasModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'agendas',
    loadChildren: () => import('./agendas/agendas.module').then(m => m.AgendasModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'cronogramas',
    loadChildren: () => import('./cronograma/cronograma.module').then(m => m.CronogramaModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'agenda-diaria-visitante',
    loadChildren: () => import('./agenda-diaria-visitante/agenda-diaria-visitante.module').then(m => m.AgendaDiariaVisitanteModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'agenda-visitante',
    loadChildren: () => import('./agenda-visitante/agenda-visitante.module').then(m => m.AgendaVisitanteModule),
    canActivate: [AuthGuard]
  },
    {
      path: 'cardapio',
      loadChildren: () => import('./cardapios/cardapios.module').then(m => m.CardapiosModule),
      canActivate: [AuthGuard]
    },
     {
      path: 'restaurante',
      loadChildren: () => import('./restaurante/restaurante.module').then(m => m.RestauranteModule),
      canActivate: [AuthGuard]
    }, 
  {
    path: 'ouvidoria',
    loadChildren: () => import('./ouvidoria/ouvidoria.module').then(m => m.OuvidoriaModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'lavanderia',
    loadChildren: () => import('./lavanderia/lavanderia.module').then(m => m.LavanderiaModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'enquete',
    loadChildren: () => import('./enquete/enquete.module').then(m => m.EnqueteModule),
    canActivate: [AuthGuard],
    canActivateChild: [EnqueteGuard]
  },
  {
    path: 'licencas',
    loadChildren: () => import('./licencas/licenca.module').then(m => m.LicencaModule),
    canActivate: [AuthGuard],
    /*canActivateChild: [EnqueteGuard] */
  },
  {
    path: 'licencas/licencas-extras',
    loadChildren: () => import('./licencas/licenca.module').then(m => m.LicencaModule),
    canActivate: [AuthGuard],
    /*canActivateChild: [EnqueteGuard] */
  },
  {
    path: 'pesquisa',
    loadChildren: () => import('./pesquisa-satisfacao/pesquisa-satisfacao.module').then(m => m.PesquisaSatisfacaoModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'peso',
    loadChildren: () => import('./peso/peso.module').then(m => m.PesoModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboards',
    loadChildren: () => import('./dashboards/dashboards.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard],
    /*canActivateChild: [EnqueteGuard] */
  },
  {
    path: 'informativo',
    loadChildren: () => import('./informativo/informativo.module').then(m => m.InformativoModule),
    canActivate: [AuthGuard],
    /*canActivateChild: [EnqueteGuard] */
  },
  {
    path: 'fale-com-rh',
    loadChildren: () => import('./fale-com-rh/fale-com-rh.module').then(m => m.FaleComRhModule),
    canActivate: [ExternalGuard],
    /*canActivateChild: [EnqueteGuard] */
  },
  {
    path: 'ramais',
    loadChildren: () => import('./ramais/ramais.module').then(m => m.RamaisModule),
    canActivate: [ExternalGuard],
    /*canActivateChild: [EnqueteGuard] */
  },
  {
    path: 'academia',
    loadChildren: () => import('./academia/academia.module').then(m => m.AcademiaModule),
    // canActivate: [ExternalGuard],
    /*canActivateChild: [EnqueteGuard] */
  },
  {
    path: 'yoga',
    loadChildren: () => import('./yoga/yoga.module').then(m => m.YogaModule),
    // canActivate: [ExternalGuard],
    /*canActivateChild: [EnqueteGuard] */
  },
  {
    path: 'acupuntura',
    loadChildren: () => import('./acupuntura/acupuntura.module').then(m => m.AcupunturaModule),
    // canActivate: [ExternalGuard],
    /*canActivateChild: [EnqueteGuard] */
  },
  {
    path: 'fisioterapia',
    loadChildren: () => import('./fisioterapia/fisioterapia.module').then(m => m.FisioterapiaModule),
    // canActivate: [ExternalGuard],
    /*canActivateChild: [EnqueteGuard] */
  },
  {
    path: 'dermato',
    loadChildren: () => import('./dermato/dermato.module').then(m => m.DermatoModule),
    // canActivate: [ExternalGuard],
    /*canActivateChild: [EnqueteGuard] */
  },
  {
    path: 'hidroterapia',
    loadChildren: () => import('./hidroterapia/hidroterapia.module').then(m => m.HidroterapiaModule),
    // canActivate: [ExternalGuard],
    /*canActivateChild: [EnqueteGuard] */
  },
  {
    path: 'refeitorio',
    loadChildren: () => import('./refeitorio/refeitorio.module').then(m => m.RefeitorioModule),
    // canActivate: [ExternalGuard],
    /*canActivateChild: [EnqueteGuard] */
  },
  /* {
    path: 'recreacao',
    loadChildren: () => import('./recreacao/recreacao.module').then(m => m.RecreacaoModule),
    // canActivate: [ExternalGuard],
    // canActivateChild: [EnqueteGuard]
  }, */
  {
    path: 'reserva-prato-leve',
    loadChildren: () => import('./pagina-externa/pagina-externa.module').then(m => m.PaginaExternaModule),
    canActivate: [ExternalGuard],
    /*canActivateChild: [EnqueteGuard] */
  },
  {
    path: '',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '**',
    redirectTo: '/'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
