import {ModuleWithProviders, NgModule} from '@angular/core';
import { LoginPageComponent } from './login-page/login-page.component';
import { AuthRoutingModule } from './auth-routing.module';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { AuthService } from './services/auth.service';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './ngrx/effects/auth.effects';
import { authReducer } from './ngrx/reducers';
import { AuthGuard } from './services/auth.guard';
import { TermoAcessoComponent } from './termo-acesso/termo-acesso.component';



@NgModule({
    declarations: [
        LoginPageComponent,
        TermoAcessoComponent
    ],
    imports: [
        CommonModule,
        AuthRoutingModule,
        SharedModule,
        StoreModule.forFeature('auth', authReducer),
        EffectsModule.forFeature([AuthEffects])
    ],
    exports: [LoginPageComponent]
})
export class AuthModule { 
    static forRoot(): ModuleWithProviders<AuthModule> {
        return {
            ngModule: AuthModule,
            providers: [
              AuthService,
                AuthGuard
            ]
        }
    }
}
