<div class="header">
  
  <h1 mat-dialog-title  style="font-size: 18px;">Olá, {{data.title}}</h1>

  <span>
    <button mat-icon-button class="header-icon" >
      <mat-icon color="accent" (click)="onNoClick()">close</mat-icon>
    </button>
  </span>

</div> 
<div mat-dialog-content>
  <p>{{data?.message}}</p>
</div>
