import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import { Storage } from '../../core/services/storage.service';


@Directive({
    selector: '[checkRoles]'
})
export class CheckRolesDirective {

    constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) { }

    @Input('checkRoles') set roles(_roles: string) {
        let user: any = Storage.get('user') || Storage.localGet('user');
        if (_roles.indexOf(user?.roleId?.name) == -1 && _roles !== 'ALL') {
            this.viewContainer.clear();
        } else {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }
    }

}