import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { select, Store } from '@ngrx/store';
import { isLoggedIn } from '../ngrx/selectors/auth.selectors';
import { tap } from 'rxjs/operators';
import { AppState } from '../../reducers';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private auth: AuthService,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {

    return this.store
      .pipe(
        select(isLoggedIn),
        tap(loggedIn => {
          //console.log('loggedIn', loggedIn)
          if (!loggedIn) {
            if (state.url) {
              this.auth.setRedirectUrl(state.url);
            }
            this.router.navigateByUrl('/login');
          }
        })
      )


  }

}
