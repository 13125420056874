import {Component, Input, OnInit } from '@angular/core';




@Component({
    selector: 'cardapio-paciente-card',
    templateUrl: './cardapio-paciente-card.component.html',
    styleUrls: ['./cardapio-paciente-card.component.scss']
})
export class CardapioPacienteCardComponent implements OnInit {

   @Input()
      cardapiosPaciente: any[] =  [];         
    
    constructor() {}

    ngOnInit() {         
    }

   
}
