<ng-container *ngIf="cronograma else noFila">       
  <p class="atentimento-label" *ngIf="cronograma?.procedimentos[0]?.grupo =='1'">
    <mat-toolbar style="text-align: center; height: 35px;  border: solid; border-radius: 5px; border-color: #6c9d26;" color="accent" [ngClass]="{'comHorario': cronograma?.procedimentos[0]?.grupo =='0', 'semHorario': cronograma?.procedimentos[0]?.grupo =='1'}">                
      <span>Atendimento sem hora marcada</span>
    </mat-toolbar>
  </p>
    <mat-card *ngFor="let procedimento of cronograma.procedimentos" class="agenda-card mat-elevation-z10">     
    <!--[ngClass]="getBGColor(procedimento?.prof_tipo)"-->  
        <mat-card-content class="agenda-content">
          <mat-list class="agenda-list">
            <mat-list-item  class="list-horizontal">
              <mat-icon mat-list-icon color="primary">deck</mat-icon>                    
                  <div><strong [innerHTML]="procedimento?.proc"></strong></div>                                                
          </mat-list-item> 
            <mat-list-item *ngIf="procedimento?.grupo == '0'" class="list-horizontal">
              <mat-icon mat-list-icon color="primary">access_time</mat-icon>                    
                  <div>  <strong>{{procedimento?.hora}}h</strong></div>                                                
          </mat-list-item>  
          </mat-list>
        </mat-card-content>

    </mat-card>
</ng-container>
  <ng-template #noFila>
    <mat-card class="agenda-card mat-elevation-z10"> 
      <div style="text-align:center;font-size: 18px;">
        Não foi encontrado o cronograma!<br>Favor falar com a recepcionista!
      </div>
    </mat-card>
  </ng-template>  