<mat-toolbar color="primary" *ngIf="isLoggedIn$ | async">
  <mat-toolbar-row>
    <button class="menu-button" mat-icon-button (click)="toggleMenu()">
      <mat-icon>menu</mat-icon>
    </button>
    <span style="cursor: pointer;" routerLink="/">
      <img class="logo-consultas" [src]="logoHeader" alt="home">
    </span>

    <div fxFlex fxLayout="row" fxLayoutAlign="center center">
      <span style="cursor: pointer; margin-left: -50px;" routerLink="/">
        Hospital da Obesidade
      </span>
    </div>
    <!-- <div fxFlex fxLayout="row" fxLayoutAlign="flex-end">
          <div class="clock">
              <span style="margin-bottom: 20px !important;">
                  {{dataHoje | date: 'mediumTime'}}
              </span>
         </div>
      </div> -->
  </mat-toolbar-row>
</mat-toolbar>

<mat-sidenav-container>

  <mat-sidenav #start style="margin-top: 60px;" [opened]="isMenuOpened">
    <mat-nav-list>
      <div class="profile-card" *ngIf="(getUser$ | async) as userLogin">
        <img [src]="photoPerfil" alt="Photo de Perfil">
        <div class="header">
          <h2 style="margin: 0px;">{{userLogin?.firstName}}</h2>
          <p><strong>CPF: </strong><span style="color: #373837;">{{userLogin?.cpf | mask: '000.000.000-00'}}</span></p>
        </div>
      </div>

      <mat-divider></mat-divider>

      <a mat-list-item routerLink="/informativo" (click)="start.close()">
        <mat-icon>psychology_alt</mat-icon>
        <span>Informativo</span>
      </a>
      <mat-expansion-panel #painel>
        <mat-expansion-panel-header>
          <mat-icon>pending_actions</mat-icon>
          <span class="menu-atividade-hdo">Atividades HDO</span>
        </mat-expansion-panel-header>

        <mat-nav-list>
          <a mat-list-item *ngIf="(isAvaliacao$ | async)" routerLink="/academia"
            (click)="start.close(); closeExpansionMenu(painel)">
            <mat-icon>fitness_center</mat-icon>
            <span>Academia</span>
          </a>
          <a mat-list-item *ngIf="(isAvaliacao$ | async)" routerLink="/yoga"
            (click)="start.close(); closeExpansionMenu(painel)">
            <mat-icon>self_improvement</mat-icon>
            <span>Yoga</span>
          </a>
          <a mat-list-item *ngIf="(isAvaliacao$ | async)" routerLink="/acupuntura"
            (click)="start.close(); closeExpansionMenu(painel)">
            <mat-icon>airline_seat_flat</mat-icon>
            <span>Acupuntura</span>
          </a>
          <a mat-list-item *ngIf="(isAvaliacao$ | async)" routerLink="/fisioterapia"
            (click)="start.close(); closeExpansionMenu(painel)">
            <mat-icon>accessibility_new</mat-icon>
            <span>Fisioterapia Motora / RPG / Respiratória</span>
          </a>
          <a mat-list-item *ngIf="(isAvaliacao$ | async)" routerLink="/dermato"
            (click)="start.close(); closeExpansionMenu(painel)">
            <mat-icon>star</mat-icon>
            <span>Dermato / Hidro com Sais</span>
          </a>
          <a mat-list-item *ngIf="(isAvaliacao$ | async)" routerLink="/hidroterapia"
            (click)="start.close(); closeExpansionMenu(painel)">
            <mat-icon>hot_tub</mat-icon>
            <span>Hidroterapia</span>
          </a>
          <a mat-list-item *ngIf="(isAvaliacao$ | async)" routerLink="/refeitorio"
            (click)="start.close(); closeExpansionMenu(painel)">
            <mat-icon>flatware</mat-icon>
            <span>Refeitório</span>
          </a>
          <!-- <a mat-list-item *ngIf="(isAvaliacao$ | async)" routerLink="/recreacao"
            (click)="start.close(); closeExpansionMenu(painel)">
            <mat-icon>celebration</mat-icon>
            <span>Recreação</span>
          </a> -->
        </mat-nav-list>
      </mat-expansion-panel>
      <a mat-list-item *ngIf="isRecepcao$ | async" routerLink="/users" (click)="start.close()">
        <mat-icon>perm_identity</mat-icon>
        <span>Usuários</span>
      </a>
      <a mat-list-item *ngIf="isAdmin$ | async" routerLink="/roles" (click)="start.close()">
        <mat-icon>face</mat-icon>
        <span>Regras</span>
      </a>
      <a mat-list-item *ngIf="isAvaliacao$ | async" routerLink="/filas" (click)="start.close()">
        <mat-icon>people</mat-icon>
        <span>Filas</span>
      </a>
      <a mat-list-item *ngIf="(isAvaliacao$ | async)" routerLink="/agendas" (click)="start.close()">
        <mat-icon>schedule</mat-icon>
        <span>Agenda</span>
      </a>
      <a mat-list-item *ngIf="(isAvaliacao$ | async)" routerLink="/cronogramas" (click)="start.close()">
        <mat-icon>date_range</mat-icon>
        <span>Cronograma</span>
      </a>
      <a mat-list-item *ngIf="isAvaliacao$ | async" routerLink="/agenda-diaria-visitante" (click)="start.close()">
        <mat-icon>group_add</mat-icon>
        <!-- <span>Visitas Seg a Sex</span> -->
        <span>Visitas</span>
      </a>
     <!--  <a mat-list-item *ngIf="isAvaliacao$ | async" routerLink="/agenda-visitante" (click)="start.close()">
        <mat-icon>group_add</mat-icon>
        <span>Visitas Sab a Dom</span>
      </a> -->
      <a mat-list-item *ngIf="(isAvaliacao$ | async)" routerLink="/licencas" (click)="start.close()">
        <mat-icon>medical_services</mat-icon>
        <span>Exames</span>
      </a>
      <a mat-list-item *ngIf="isAvaliacao$ | async" routerLink="/licencas/licencas-extras" (click)="start.close()">
        <mat-icon>medical_services</mat-icon>
        <span>Licenças</span>
      </a>
      <a mat-list-item *ngIf="(isAvaliacao$ | async)" routerLink="/pesquisa" (click)="start.close()">
        <mat-icon>content_paste</mat-icon>
        <span>Pesquisa de Satisfação</span>
      </a>
      <a mat-list-item *ngIf="(isAvaliacao$ | async)" routerLink="/dashboards" (click)="start.close()">
        <mat-icon>auto_graph</mat-icon>
        <span>Acompanhar Peso</span>
      </a>
      <a mat-list-item *ngIf="(isAvaliacao$ | async)" routerLink="/dashboards/pesagem" (click)="start.close()">
        <mat-icon>fitness_center</mat-icon>
        <span>Acompanhar Pesagem</span>
      </a>
      <a mat-list-item *ngIf="(isAvaliacao$ | async)" routerLink="/lavanderia" (click)="start.close()">
        <mat-icon>local_laundry_service</mat-icon>
        <span>Lavanderia</span>
      </a>
      <a mat-list-item *ngIf="(isAvaliacao$ | async)" routerLink="/ouvidoria" (click)="start.close()">
        <mat-icon>hearing</mat-icon>
        <span>Ouvidoria</span>
      </a>

      <a mat-list-item *ngIf="(isAvaliacao$ | async)" routerLink="/ouvidoria/ti" (click)="start.close()">
        <mat-icon>campaign</mat-icon>
        <span>Fale Com TI</span>
      </a>
      
      <!-- <a mat-list-item *ngIf="(isPaciente$ | async) || (isAdmin$ | async) && (user?.nivelAcesso === 1)"
        routerLink="/peso">        
        <mat-icon>monitor_weight</mat-icon>
        <span>Peso</span>
      </a> -->
      <!-- <a mat-list-item *ngIf="mostrarEnquete" routerLink="/enquete"> -->
      <!-- <a mat-list-item *ngIf="mostrarPesquisa" routerLink="/pesquisa"> -->

       <a mat-list-item *ngIf="isAvaliacao$ | async"  (click)="start.close()"  routerLink="/cardapio">
                <mat-icon>local_library</mat-icon>
                <span>Cardápio do Dia</span>
          </a>
          <a mat-list-item *ngIf="isAvaliacao$ | async"  (click)="start.close()" routerLink="/cardapio/almoco-e-jantar">
                <mat-icon>restaurant</mat-icon>
                <span>Meus Pedidos</span>
          </a>
          <a mat-list-item *ngIf="isRecepcao$ | async"   (click)="start.close()" routerLink="/restaurante">
                <mat-icon>flutter_dash</mat-icon>
                <span>Todos Pedidos</span>
          </a>           
      <a mat-list-item (click)="openDialogChangePassword()" (click)="start.close()">
        <mat-icon>vpn_key</mat-icon>
        <span>Mudar Senha</span>
      </a>
      <a mat-list-item (click)="logout()" (click)="start.close()">
        <mat-icon>exit_to_app</mat-icon>
        <span>Logout</span>
      </a>

    </mat-nav-list>

  </mat-sidenav>
  <mat-sidenav-content>
    <app-loader></app-loader>
    <router-outlet></router-outlet>
  </mat-sidenav-content>

</mat-sidenav-container>