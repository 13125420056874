import {Injectable} from "@angular/core";
import {HttpClient } from "@angular/common/http";
import {Observable } from "rxjs";
import { map, shareReplay, tap } from "rxjs/operators";
import * as  APPUtils from '../../core/utils/app.utils';
import { Cardapio } from "src/app/core/model/cardapio";
import { cardapioPaciente } from "src/app/core/model/cardapioPaciente";
import { Storage } from "src/app/core/services/storage.service";


@Injectable({
    providedIn: 'root'
  })
export class CardapioHttpService {
        
    urlBase = APPUtils.URLBASE;
    constructor(private httpClient:HttpClient) {

    }

    getAllByCPF(status = true): Observable<Cardapio[]> {
        const user: any = Storage.get('user');
        let cpf = user ? user.cpf : '';        
        return this.httpClient.get(`${this.urlBase}/api/opcoes/getAllByCPF?cpf=${cpf}&status=${status}`)
            .pipe(
                map((res: any) => res['data']['data']),
                //tap(data => console.log("cardapios", data)),
                shareReplay()
            );
    }

    getAllCardapioPacienteByCPF(cpf: any): Observable<cardapioPaciente[]> {
   
        return this.httpClient.get(`${this.urlBase}/api/opcoesPaciente/getAllByCPF?cpf=${cpf}`)
            .pipe(
                map((res: any) => res['data']['data']),
                shareReplay()
            );
    }
    

    isSendPedido(): Observable<cardapioPaciente[]> {
        const user: any = Storage.get('user');
        let cpf = user ? user.cpf : '';
        return this.httpClient.get(`${this.urlBase}/api/opcoesPaciente/isSendPedido?cpf=${cpf}`)
            .pipe(
                map((res: any) => res['data']['data']),
               // tap(console.log),
                shareReplay()
            );
    }    
    
    vagasDisponiveis(): Observable<cardapioPaciente[]> {
        const user: any = Storage.get('user');
        let cpf = user ? user.cpf : '';
        return this.httpClient.get(`${this.urlBase}/api/opcoesPaciente/vagasDisponiveis`)
            .pipe(
                map((res: any) => res['data']['data']),
               // tap(console.log),
                shareReplay()
            );
    }

    createCardapioPaciente(cardapio: any): Observable<cardapioPaciente[]> {        
        return new Observable<any>(obs => {
            this.httpClient.post(`${APPUtils.URLBASE}/api/opcoesPaciente`, cardapio)
            .subscribe((result: any) => {

                obs.next(result);
                obs.complete();
                
            }, err => {
                obs.error(err)
            });
        });
    }
    
/*     findAllCardapios(): Observable<Cardapio[]> {

        const user: any = localStorage.getItem("user");
        let cpf = user ? JSON.parse(user).cpf : '';
        //console.log('CPF', JSON.parse(cpf))
        const skip = 0;
        const limit = 100;
        return this.httpClient.get(`${this.urlBase}/api/cardapios/${cpf}`)
            .pipe(
                map((res: any) => res['cardapios']),
                shareReplay()
            );
    } */
    


}