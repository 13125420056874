import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  private notify = new Subject();
  public notfyObservable$ = this.notify.asObservable();
  
  constructor(private _snackBar: MatSnackBar) { }

  /**SnackBar to mobile */
  showMessageMobile(message: any, action: any, duration: any, position?: any) {
    this._snackBar.open(message, action, {
      duration: duration,
      horizontalPosition: 'center',
      verticalPosition: position? position :'top',
    });
  }

}