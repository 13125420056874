import {Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Fila } from 'src/app/core/model/fila';
import { Storage } from 'src/app/core/services/storage.service';
import * as  APPUtils from '../../../core/utils/app.utils';

@Component({
    selector: 'filas-card',
    templateUrl: './filas-card.component.html',
    styleUrls: ['./filas-card.component.scss']
})
export class FilasCardComponent implements OnInit {

    @Input() clientes: Fila[] =  [];  
    @Input() user: any;  

    @Output()
    clienteChanged = new EventEmitter();

    warniningCard = APPUtils.filaCard;

    constructor() {
    }

    ngOnInit() {
        //this.user = Storage.get('user');             
    }
    
}
