<div class="header">
  
  <h1 mat-dialog-title  style="font-size: 14px;">{{data?.userId?.name}}</h1>

  <span>
    <button mat-icon-button class="header-icon" >
      <mat-icon color="accent" (click)="onNoClick()">close</mat-icon>
    </button>
  </span>

</div> 
<div mat-dialog-content style="padding: 0px; margin-top: -60px;">
  <ng-container *ngIf="cardapiosPaciente$ | async as cardapios else beginnerLoading">
    <!-- {{cardapios | json}} -->
    <cardapio-paciente-card   [cardapiosPaciente]="cardapios"></cardapio-paciente-card>   
  </ng-container> 
  <ng-template class="spinner-container" #beginnerLoading><mat-spinner></mat-spinner></ng-template>
</div>
