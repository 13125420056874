import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatBadgeModule } from '@angular/material/badge';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxMaskModule, IConfig } from 'ngx-mask';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ChangePasswordUserComponent } from './modals/change-password-user/change-password-user.component';
import { DialogUpdateComponent } from './modals/dialog-update/dialog-update.component';
import { CheckAccessDirective } from './directives/check-access.directive';
import { CheckRolesDirective } from './directives/check-roles.directive';
import { AgendaCardComponent } from './components/agenda-card/agenda-card.component';
import { FilasCardComponent } from './components/filas-card/filas-card.component';
import { CronogramaCardComponent } from './components/cronograma-card/cronograma-card.component';
import { DialogConfirmationComponent } from './modals/dialog-confirmation/dialog-confirmation.component';
import { DialogWarningComponent } from './modals/dialog-warning/dialog-warning.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatRadioModule } from '@angular/material/radio';
import { MatStepperModule } from '@angular/material/stepper';
import { CdkStepperModule } from '@angular/cdk/stepper';
export var options: Partial<IConfig> | (() => Partial<IConfig>);



import { RouterModule } from '@angular/router';


import { CdkTreeModule } from '@angular/cdk/tree';

import { MatButtonToggleModule } from '@angular/material/button-toggle';


import { MatExpansionModule } from '@angular/material/expansion';

import { MatMenuModule } from '@angular/material/menu';
import { MatOptionModule, NativeDateModule } from '@angular/material/core';


import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { LayoutModule } from '@angular/cdk/layout';
import { OverlayModule } from '@angular/cdk/overlay';
import { DialogInfoOpcaoComponent } from './modals/dialog-info-opcao/dialog-info-opcao.component';
import { TruncatePipe } from './pipes/limite-to.pipe';
import { CardAcompanharPesoComponent } from './components/card-acompanhar-peso/card-acompanhar-peso.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { VisitantesListComponent } from '../agenda-visitante/visitantes-list/visitantes-list.component';
import { DialogMeusPedidosComponent } from './modals/dialog-meus-pedidos/dialog-meus-pedidos.component';
import { CardapioPacienteCardComponent } from './components/cardapio-paciente-card/cardapio-paciente-card.component';
import { ItemCardTabComponent } from './components/item-card-tab/item-card-tab.component';
import { LoaderComponent } from './components/loader/loader.component';
import { HorarioFormComponent } from '../agenda-visitante/horario-form/horario-form.component';

const MaterialComponents = [
    CommonModule,
    CdkTreeModule,
    MatSnackBarModule,
    ReactiveFormsModule,
    MatGridListModule,
    MatCardModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatTabsModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatSelectModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatListModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    FlexLayoutModule,
    MatCheckboxModule,
    MatBadgeModule,
    MatButtonToggleModule,
    MatToolbarModule,
    NativeDateModule,
    LayoutModule,
    OverlayModule,
    MatRadioModule,
    MatOptionModule,
    MatMenuModule,
    MatSidenavModule,
    MatStepperModule,
    CdkStepperModule,
    RouterModule,
    HighchartsChartModule,
    MatExpansionModule
]

const declarations = [
    ChangePasswordUserComponent,
    DialogUpdateComponent,
    CheckAccessDirective,
    CheckRolesDirective,
    AgendaCardComponent,
    FilasCardComponent,
    CronogramaCardComponent,
    DialogConfirmationComponent,
    DialogWarningComponent,
    DialogInfoOpcaoComponent,
    TruncatePipe,
    CardAcompanharPesoComponent,
    VisitantesListComponent,
    DialogMeusPedidosComponent,
    CardapioPacienteCardComponent,
    ItemCardTabComponent,
    LoaderComponent,
    HorarioFormComponent,
]


@NgModule({
    declarations: [...declarations],
    imports: [MaterialComponents],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [...declarations, MaterialComponents]
})
export class SharedModule { }
