import {Directive, Input} from '@angular/core';
import {Router} from "@angular/router";
import { Storage } from '../../core/services/storage.service';

@Directive({
    selector: '[checkAccess]'
})
export class CheckAccessDirective {

    @Input('checkAccess') roles: string | undefined;

    constructor(router: Router) {
        let user: any = Storage.get('user') || Storage.localGet('user');
        console.log('user',user)
        setTimeout(() => {
            if (this.roles?.indexOf(user?.role?.sigla) == -1) {
                router.navigate(['/filas']);
            }
        }, 10);
    }

}