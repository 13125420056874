import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import {
    ActionReducer,
    ActionReducerMap,
    createFeatureSelector, createReducer,
    createSelector,
    MetaReducer, on
} from '@ngrx/store';


import {AuthActions} from '../actions/action-types';


export interface AuthState {
    ids: any;
    user: any
}

/* 
export const initialAuthState: AuthState = {
    user: undefined
}; */
//export const adapter = createEntityAdapter<any>();

export const adapter: EntityAdapter<any> = createEntityAdapter<any>({
    selectId: (user: any) => user.cpf    
} );
export const initialAuthState = adapter.getInitialState({
    user: undefined
});


export const authReducer = createReducer(

    initialAuthState,

    on(AuthActions.login, (state, action) =>
    adapter.upsertOne(action.user, {...state, user: action.user}) ),
    //adapter.addOne(action.user, state) ),
    
    on(AuthActions.logout, (state, action) =>
    adapter.removeOne(action.type, state) ),
    //adapter.removeOne(action.type, state) ),


  /*   on(AuthActions.login, (state, action) => {
        return {
            user: action.user
        }
    }), */
    
/*     on(AuthActions.logout, (state, action) => {
        return {
            user: undefined
        }
    }), */

    



);

export const {
    selectAll
} = adapter.getSelectors();