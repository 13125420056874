import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class Storage {

    constructor() { }

    static get(index: any) {
        const obj = sessionStorage.getItem(index);
        return obj ? JSON.parse(obj) : null;
    }

    static set(index:any, object:any) {
        sessionStorage.setItem(index, JSON.stringify(object));
    }

    static localGet(index:any) {
        const obj = localStorage.getItem(index);
        return obj ? JSON.parse(obj) : null;
    }

    static localSet(index:any, object:any) {
        localStorage.setItem(index, JSON.stringify(object));
    }

    static getTemp() {
        const obj = sessionStorage.getItem('temp');
        return obj ? JSON.parse(obj) : null;
    }

    static setTemp(object:any) {
        sessionStorage.setItem('temp', JSON.stringify(object));
    }

    static clearTemp() {
        sessionStorage.removeItem('temp');
    }

    static clear() {
        sessionStorage.clear();
        localStorage.clear();
    }

}