<div class="header">
  
   <h1 mat-dialog-title  style="font-size: 14px; line-height: normal;">{{opcao?.titulo}}</h1> 
  <!-- <mat-card-title>{{opcao?.titulo | limitTo: 10 }}</mat-card-title> -->

  <span>
    <button mat-icon-button class="header-icon" >
      <mat-icon color="accent" (click)="onNoClick()">close</mat-icon>
    </button>
  </span>

</div> 
<div mat-dialog-content>
  <mat-card class="example-card">   
    <img mat-card-image [src]="opcao.img ? 'data:image/jpg;base64,'+opcao.img : defaultFood"  alt="Foto do Prato">
    <mat-card-subtitle>{{opcao?.descricao}}</mat-card-subtitle>    
    <mat-card-content>
      <p style="text-align: justify;
      text-justify: inter-word;">
       <strong>Ingredientes:</strong> {{opcao?.ingredientes}}
      </p>
    </mat-card-content>    
  </mat-card>
</div>
