<ng-container *ngIf="clientes.length > 0 else noFila">

  <mat-card *ngFor="let cliente of clientes" class="course-card  mat-elevation-z10"
    [ngStyle]="{'background-color':cliente.pos < 1 ? 'rgb(230 203 15)' : 'white' }">
    <!-- [ngStyle]="{'background-image':cliente.pos < 1 ? 'url(http://localhost:4200/assets/img/logo-clinica.jpg)' : 'white' }"> -->

    <div class="header course-header">
      <h2 class="title course-title" style="text-align: center;"><strong>{{cliente.nome}}</strong></h2>
    </div>

    <mat-card-content class="course-content">
      <mat-list class="course-list">
        <mat-list-item class="list-horizontal">
          <mat-icon mat-list-icon color="primary">person</mat-icon>
          <div>Profissional: <strong>{{cliente.prof}}</strong></div>
        </mat-list-item>
        <mat-list-item class="list-horizontal" *ngIf="cliente.sala">
          <mat-icon mat-list-icon color="primary">compare</mat-icon>
          <div>Consultório: <strong>{{cliente.sala}}</strong></div>
        </mat-list-item>
        <mat-list-item class="list-horizontal" *ngIf="cliente.pos > 0">
          <mat-icon mat-list-icon color="primary">campaign</mat-icon>
          <div>Posição na Fila: <strong>{{cliente.pos}}</strong></div>
        </mat-list-item>        
        <mat-list-item class="list-horizontal" *ngIf="cliente.pos == 0">
          <mat-icon mat-list-icon color="primary">access_time</mat-icon>
          <div> Você está sendo Chamado: <strong>{{cliente.hora_atendimento}}hs</strong></div>
        </mat-list-item>
      </mat-list>
    </mat-card-content>
    <mat-card-actions class="course-actions">
    </mat-card-actions>

  </mat-card>
</ng-container>
<ng-template #noFila>
  <mat-card class="course-card mat-elevation-z10">
    <!--  <img mat-card-image [src]="warniningCard" alt="Warning"> -->
    <div style="text-align:center;font-size: 18px;">
      <span style="color: orange;"><strong>{{user?.name}}</strong></span><br />Você ainda não está na FILA de
      atendimento!<br />Favor falar com a recepcionista!
    </div>
  </mat-card>
</ng-template>