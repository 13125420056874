
<ng-container *ngIf="agendas.length > 0 else noFila">       
      <div class="atentimento-label">
        <div style=" max-width: 350px; text-align: center; height: 35px;  border: solid; border-radius: 5px; border-color: #6c9d26;" color="primary" class="divisorCard">                
          <div style="margin-top: 5px;"> {{
            isGrupo(agendas[0], '0') ? 'Atendimento com hora marcada'
                                    : isGrupo(agendas[0], '1') ? 'Atendimento por ordem de chegada'
                                    : 'Atendimento sem hora marcada'
          }}</div>
        </div>
      </div>

    <mat-card *ngFor="let cliente of agendas" class="agenda-card mat-elevation-z10"
    [ngClass]="getBGColor(cliente?.prof_tipo)"
    >       
        <mat-card-content class="agenda-content">
          <mat-list class="agenda-list">
            <mat-list-item  class="list-horizontal">
              <mat-icon mat-list-icon color="primary">deck</mat-icon>                    
                  <!-- <div>Procedimento: <strong>{{cliente?.proc}}</strong></div>                                                 -->
                  <div>Atendimento: <strong>{{cliente?.proc}}</strong></div>                                                
          </mat-list-item> 
            <mat-list-item  class="list-horizontal">
              <mat-icon mat-list-icon color="primary">access_time</mat-icon>                    
                  <div> Horário: <strong>{{cliente?.hora}}</strong></div>                                                
          </mat-list-item> 
            <!-- <mat-list-item  class="list-horizontal" *ngIf="ordemChegada(cliente)">
              <mat-icon mat-list-icon style="color: white;">warning</mat-icon>                    
                  <div style="color: white;"><strong>Atendimento por ordem de chegada!</strong></div>                                                
          </mat-list-item>  --> <!-- Solicitado por Hugo -->
          </mat-list>
        </mat-card-content>

    </mat-card>
</ng-container>
  <ng-template #noFila>
    <mat-card *ngIf="showWrning" class="agenda-card mat-elevation-z10"> 
      <div style="text-align:center;font-size: 18px;">
        Não foi encontrado o agendamento!<br>Favor falar com a recepcionista!
      </div>
    </mat-card>
  </ng-template> 