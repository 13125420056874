<h1>Alterar Senha</h1>
<div class="example-container">
    <form novalidate [formGroup]="form" #formulario="ngForm" (ngSubmit)="onSubmit(formulario)">
        
        <mat-form-field appearance="fill">
        <mat-label>Senha Atual</mat-label>
        <input matInput type="password" formControlName="passwordAtual" required>
        </mat-form-field>

        <mat-form-field appearance="fill">
        <mat-label>Nova Senha</mat-label>
        <input matInput type="password" formControlName="password" required>
        </mat-form-field>
        
        <mat-form-field appearance="fill">
        <mat-label>Confirma Nova Senha</mat-label>
        <input matInput type="password" (input)="validarSenha()" formControlName="confirmPassword" required>
        </mat-form-field>

        <div *ngIf="!senhaValida && form.valid "  class="erro">
            Senhas diferentes                  
        </div>
        <div mat-dialog-actions>
            <button mat-button mat-dialog-close color="secondary">Cancelar</button>
        </div>
        
        <div>
            <button  mat-flat-button color="primary" type="submit" [disabled]="(!form.valid || !senhaValida)" (click)="alterar()">Salvar</button>
        </div>
    </form>
</div>