<div class="visitantes">

  <table mat-table [dataSource]="visitantes" class="mat-elevation-z8">
  
    <ng-container matColumnDef="dataHora">
      <th mat-header-cell *matHeaderCellDef> Data e Hora </th>
      <td mat-cell *matCellDef="let element">  {{ getFormattedDate(element?.dataHora) }}</td>
    </ng-container>

    <ng-container matColumnDef="nome">
      <th mat-header-cell *matHeaderCellDef> Nome </th>
      <td mat-cell *matCellDef="let element"> {{element.nome}} {{element.sobrenome | limitTo: 10}}</td>
    </ng-container>
  
    <ng-container matColumnDef="cpf">
      <th mat-header-cell *matHeaderCellDef> CPF </th>
      <td mat-cell *matCellDef="let element"> {{element?.cpf | mask: '000.000.000-00'}} </td>
    </ng-container>
  
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  

</div>