import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as dayjs from 'dayjs';


@Component({
  selector: 'app-visitantes-list',
  templateUrl: './visitantes-list.component.html',
  styleUrls: ['./visitantes-list.component.scss']
})
export class VisitantesListComponent implements OnInit, OnDestroy {

  @Input() visitantes: any
  @Input() showHorarios: any
  displayedColumns: string[] = ['dataHora', 'nome', 'cpf']


  constructor() { }

  ngOnInit() { }

  ngOnDestroy() { }

  getFormattedDate(dataHora: any): string {
    if (!dataHora) return '';
    const date = dayjs(dataHora);

    return this.showHorarios ? date.format('DD/MM/YYYY HH:mm') : date.format('DD/MM/YYYY');
  }

}