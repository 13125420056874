<div class="text">
    
    <span class="name">{{namePaciente}}</span>
    <h4 class="controle">{{ label }} <strong [ngClass]="{'total-perda': total < 0, 'total-ganho': total >= 0}">{{ total }} Kg</strong> </h4> 

  <!--   <span>
        <mat-icon color="primary">sports_kabaddi</mat-icon>
    </span>
  -->
   <!--  <span class="description">
        {{ percentage }}
    </span>   -->  
     <!-- <span> de meta</span>  -->
</div>
<div class="widget">
    <highcharts-chart [Highcharts]="Highcharts" [options]="chartOptions"
        style="width: 100%; height: 100%; display: block;">
    </highcharts-chart>
</div>