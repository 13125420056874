import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as  APPUtils from '../../../core/utils/app.utils';


@Component({
    selector: 'dialog-info-opcao',
    templateUrl: './dialog-info-opcao.component.html',
    styleUrls: ['./dialog-info-opcao.component.scss']
})
export class DialogInfoOpcaoComponent {

  opcao: any;
  defaultFood = APPUtils.default_food;

   constructor(
      public dialogRef: MatDialogRef<DialogInfoOpcaoComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any) {        
        this.opcao = data['opcao']
      }
  
    onNoClick(): void {
      this.dialogRef.close();
    }
}
