import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Enquete } from 'src/app/core/model/enquete';

import * as APPUtils from '../../core/utils/app.utils';


@Injectable({
    providedIn: 'root'
  })
export class EnqueteService {

    urlBase = APPUtils.URLBASE;
    //pergunta = new Observable<Pergunta>();

    constructor(private httpClient:HttpClient) {

    }
    mostrarMenuEnquete(userId: any): Observable<any> {
      return this.httpClient.get(`${APPUtils.URLBASE}/api/enquetes/enableMenuEnquete?userId=${userId}`);
    }
    createEnquete(enquete: Enquete): Observable<Enquete> {
      return this.httpClient.post<Enquete>(`${APPUtils.URLBASE}/api/enquetes`, enquete);
    }
/* 

    createEnquete2(enquete: criarEnquete): Observable<criarEnquete> {
      return this.httpClient.post<criarEnquete>(`${APPUtils.URLBASE}/api/criarEnquete`, enquete);
    }
    createPergunta(pergunta: Pergunta): Observable<Pergunta> {
      return this.httpClient.post<Pergunta>(`${APPUtils.URLBASE}/api/pergunta`, pergunta);
    }


    listPergunta(): Observable<Pergunta[]> {
      return this.httpClient.get<Pergunta[]>(`${APPUtils.URLBASE}/api/pergunta`);
    }
    listEnquete(): Observable<criarEnquete[]> {
      return this.httpClient.get<criarEnquete[]>(`${APPUtils.URLBASE}/api/criarEnquete`);
    }


    listPerguntaId(id: any): Observable<Pergunta> {
      return this.httpClient.get<Pergunta>(`${APPUtils.URLBASE}/api/pergunta` + `/${id}`);
    }
    listRespostaId(p: Pergunta) {
      return this.httpClient.get(`${APPUtils.URLBASE}/api/pergunta` + `/${p._id}`)
    }


    updatePergunta(p: Pergunta): Observable<Pergunta> {
      return this.httpClient.put<Pergunta>(`${APPUtils.URLBASE}/api/pergunta` + `/${p._id}`, p);
    }


    deletePergunta(p: Pergunta) {
      return this.httpClient.delete(`${APPUtils.URLBASE}/api/pergunta` + `/${p._id}`);
    }
    deleteEnquete(e: criarEnquete) {
      return this.httpClient.delete(`${APPUtils.URLBASE}/api/criarEnquete` + `/${e._id}`);
    }
 */
   

}
