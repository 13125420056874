import { Component, OnInit } from '@angular/core';
import { Router} from "@angular/router";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { AuthService } from '../services/auth.service';
import { login } from '../ngrx/actions/auth.actions';
import { Storage } from 'src/app/core/services/storage.service';


@Component({
  selector: 'app-termo-acesso',
  templateUrl: './termo-acesso.component.html',
  styleUrls: ['./termo-acesso.component.scss']
})
export class TermoAcessoComponent implements OnInit   {
    hide = true;
    user: any;

    showLoading: boolean = false;    
    error = null;

    form: UntypedFormGroup;    

    constructor(
      private fb:UntypedFormBuilder,
      private store: Store<AppState>,
      private auth: AuthService,    
      private router:Router,      
      ){
        this.form = fb.group({
            termoAcesso: [false, [Validators.required]],          
        });

      } 

  ngOnInit() {  }

    onSubmit(){
      let user: any = Storage.get('user');
      const termoAcesso = this.form.value.termoAcesso;
      user['termoAcesso'] = termoAcesso;
        if(termoAcesso === true) {
          this.auth.updateTermoAcesso(user).subscribe(resultUser => {

            let token = user['token'];

            user = {...resultUser, token};
            Storage.set('user',user);     
            //localStorage.setItem('user',  JSON.stringify(user));      
            this.store.dispatch(login({user}));

            this.router.navigateByUrl('filas')
          }, erro => {
            console.log(erro);        
            }
          );
        } else {
          alert('Para acessar a aplicação você precisa atualizar o termo!')
        }
    }    

}
