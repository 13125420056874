<div class="home-panel">
  <ng-container *ngIf="cardapiosPaciente else noFila"> 
    
    
      <mat-card class="cardapio-card">   
    
     <!--  [ngClass]="getBGColor(cliente?.prof_tipo)" -->
        <mat-tab-group class="grupo-tab">
       
          <mat-tab label="Café" >            
            <mat-card-content class="cardapio-content">
              <div *ngFor="let cardapio of cardapiosPaciente; let i=index;" >                            
                <item-card-tab [opcoes]="cardapio?.opcoesCafe" [refeicao]="1" [hora]="cardapio?.horaRefeicao?.cafe"  [dataRefeicao]="cardapio?.dataRefeicao" [titulo]="'Itens do Café:'" [iconList]="'check_circle'" [iconTitle]="'fact_check'"></item-card-tab>
                <item-card-tab [opcoes]="cardapio?.cafeExtra" [titulo]="'Itens Extras'" [iconList]="'check_circle_outline'" [iconTitle]="'checklist_rtl'"></item-card-tab>
                <mat-form-field class="observacao" appearance="outline">
                  <mat-label>Observações sobre o café</mat-label>
                  <textarea matInput [value]="cardapio?.cafeObs"></textarea>
                </mat-form-field>            
              </div>            
            </mat-card-content>
          </mat-tab>
          <mat-tab label="Almoço" >            
            <mat-card-content class="cardapio-content">
              <div *ngFor="let cardapio of cardapiosPaciente; let i=index;" >
                <item-card-tab [opcoes]="cardapio?.opcoesAlmoco" [refeicao]="2"  [hora]="cardapio?.horaRefeicao?.almoco"  [dataRefeicao]="cardapio?.dataRefeicao" [titulo]="'Itens do Almoço:'" [iconList]="'check_circle'" [iconTitle]="'fact_check'"></item-card-tab>
                <item-card-tab [opcoes]="cardapio?.almocoExtra" [titulo]="'Itens Extras'" [iconList]="'check_circle_outline'" [iconTitle]="'checklist_rtl'"></item-card-tab>
                 <mat-form-field class="observacao" appearance="outline">
                  <mat-label>Observações sobre o almoço</mat-label>
                  <textarea matInput [value]="cardapio?.almocoObs"></textarea>
                </mat-form-field> 
              </div>            
            </mat-card-content>
          </mat-tab>
          <mat-tab label="Lanche" >            
            <mat-card-content class="cardapio-content">
              <div *ngFor="let cardapio of cardapiosPaciente; let i=index;" >
                <item-card-tab [opcoes]="cardapio?.opcoesLanches" [refeicao]="3"  [hora]="cardapio?.horaRefeicao?.lanche"  [dataRefeicao]="cardapio?.dataRefeicao" [titulo]="'Itens do Lanche:'" [iconList]="'check_circle'" [iconTitle]="'fact_check'"></item-card-tab>
                <item-card-tab [opcoes]="cardapio?.lancheExtra" [titulo]="'Itens Extras'" [iconList]="'check_circle_outline'" [iconTitle]="'checklist_rtl'"></item-card-tab>           
                 <mat-form-field class="observacao" appearance="outline">
                  <mat-label>Observações sobre o Lanche</mat-label>
                  <textarea matInput [value]="cardapio?.lancheObs"></textarea>
                </mat-form-field> 
              </div>            
            </mat-card-content>
          </mat-tab>
          <mat-tab label="Jantar" >            
            <mat-card-content class="cardapio-content">
              <div *ngFor="let cardapio of cardapiosPaciente; let i=index;" >
                <item-card-tab [opcoes]="cardapio?.opcoesJantar" [refeicao]="4"   [hora]="cardapio?.horaRefeicao?.jantar"  [dataRefeicao]="cardapio?.dataRefeicao" [titulo]="'Itens do Jantar:'" [iconList]="'check_circle'" [iconTitle]="'fact_check'"></item-card-tab>
                <item-card-tab [opcoes]="cardapio?.jantarExtra" [titulo]="'Itens Extras'" [iconList]="'check_circle_outline'" [iconTitle]="'checklist_rtl'"></item-card-tab>
                 <mat-form-field class="observacao" appearance="outline">
                  <mat-label>Observações sobre o café</mat-label>
                  <textarea matInput [value]="cardapio?.jantarObs"></textarea>
                </mat-form-field>  
              </div>            
            </mat-card-content>
          </mat-tab>          
        </mat-tab-group>       
  
      </mat-card>
  </ng-container>
    <ng-template #noFila>
      <mat-card class="cardapio-card mat-elevation-z10">      
        <div style="text-align:center;font-size: 18px;">
          Não foi encontrado cardápio!
        </div>
      </mat-card>
    </ng-template> 
</div>