import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';

import { AppState } from '../../reducers';
import { UtilsService } from 'src/app/core/services/utils.service';

@Injectable({
  providedIn: 'root'
})
export class ExternalGuard implements CanActivate {

  constructor(
    private store: Store<AppState>,
    private router: Router) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return of(UtilsService.getCk(route.queryParams.ck))

  }

  backPage() {
    history.back();
  }
}
