import {Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Agenda } from 'src/app/core/model/agenda';
import * as  APPUtils from '../../../core/utils/app.utils';

@Component({
    selector: 'agenda-card',
    templateUrl: './agenda-card.component.html',
    styleUrls: ['./agenda-card.component.scss']
})
export class AgendaCardComponent implements OnInit {

    @Input() agendas: Agenda[] =  [];    
    @Input() showWrning = false;    

    @Output()
    clienteChanged = new EventEmitter();

    warniningCard = APPUtils.agendaCard;

    constructor() {
    }

    ngOnInit() {   
        
    }
    ordemChegada(agenda: any) {
       return (
          (agenda.prof_tipo == "M&Eacute;DICO") 
          || (agenda.prof_tipo == "NUTRICIONISTA" && agenda.proc == "BIOIMPEDANCIOMETRIA")) 
          ? true : false;
    }

    getBGColor(tipo: any){       
        switch(tipo) { 
            case 'NUTRICIONISTA': { 
               //statements; 
               return 'nutricionista-cor'               
            } 
            case 'PSIC&Oacute;LOGO': { 
               //statements; 
               return 'psicologo-cor' 
            } 
            case 'TERAPEUTA': { 
               //statements; 
               return 'terapeuta-cor' 
            } 
            case 'M&Eacute;DICO': { 
               //statements; 
               return 'medico-cor' 
            } 
            case 'PESAGEM': { 
               //statements; 
               return 'pesagem-cor' 
            } 
            case 'MEDICACAO': { 
               //statements; 
               return 'medicacao-cor' 
            } 
            default: { 
               //FISIOTERAPEUTA; 
               return 'fisioterapeuta-cor' 
            } 
         } 
    }
    isGrupo(agenda: any, numero: any) {
      return agenda?.grupo == numero
    } 
}
