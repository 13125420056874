import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import * as  APPUtils from '../../core/utils/app.utils';
import { map, tap } from "rxjs/operators";
import { Storage } from "src/app/core/services/storage.service";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private redirectUrl: any;

  constructor(
    private httpClient: HttpClient,
    public router: Router) { }

  login(user: any): Observable<any> {
    return new Observable<any>(obs => {
      this.httpClient.post(`${APPUtils.URLBASE}/api/authenticate`, user)
        .pipe(
          map((res: any) => res['data']),
          tap(res => this.setSession(res))
        )
        .subscribe((result: any) => {

          obs.next(result);
          obs.complete();

        }, err => {
          obs.error(err)
        });
    });
  }

  updateTermoAcesso(user: any): Observable<any> {
    return new Observable<any>(obs => {
      this.httpClient.put(`${APPUtils.URLBASE}/api/pacientes/${user._id}`, user)
        .pipe(
          map((res: any) => res['data']['data']),
        ).subscribe((result: any) => {

          obs.next(result);
          obs.complete();

        }, err => obs.error(err));
    });
  }
  getUserById(userId: any): Observable<any> {
    return new Observable<any>(obs => {
      this.httpClient.get(`${APPUtils.URLBASE}/api/pacientes?id=${userId}`)
        .pipe(
          map((res: any) => res['data']['data']),
        ).subscribe((result: any) => {

          obs.next(result);
          obs.complete();

        }, err => obs.error(err));
    });
  }
  getToken(): string {
    return Storage.get('user') ? Storage.get('user').token : null;
  }
  public getUser() {
    return Storage.get('user');
  }

  private setSession(authResult: any) {
    /*  const expiresAt = moment().add(authResult.expiresIn,'d');
     Storage.set('id_token', authResult.token);
     Storage.set("expires_at", JSON.stringify(expiresAt.valueOf()) ); */
    let { data } = authResult;
    data['token'] = authResult.token;
    data = Object.assign({}, data);

    Storage.set('user', data);

  }

  setRedirectUrl(url: string): void {
    if (url !== '/login') { // Apenas armazena se não for a página de login
      this.redirectUrl = url;
    }
  }

  getRedirectUrl(): string {
    return this.redirectUrl;
  }

  clearRedirectUrl(): void {
    this.redirectUrl = null;
  }
}
