import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { AuthService } from '../services/auth.service';
import { login } from '../ngrx/actions/auth.actions';
import * as  APPUtils from '../../core/utils/app.utils';
import { MessageService } from 'src/app/core/services/message.service';
import { LoaderService } from 'src/app/core/services/loader.service';


@Component({
  selector: 'scrm-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
  hide = true;

  showLoading: boolean = false;
  error = null;

  form: UntypedFormGroup;

  logoCrmExterno = APPUtils.logo;

  onSubmit(form: any) { }

  private urlDigitada: any;


  constructor(
    private fb: UntypedFormBuilder,
    private auth: AuthService,
    private messageService: MessageService,
    private loaderService: LoaderService,
    private router: Router,
    private store: Store<AppState>) {
    this.form = fb.group({
      cpf: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });
  }

  ngOnInit() {
    const logoutIndicator = localStorage.getItem('logoutIndicator');
    if (logoutIndicator) {
      localStorage.removeItem('logoutIndicator');
    } else {
      this.urlDigitada = this.auth.getRedirectUrl()
    }
  }

  login() {
    const user = this.form.value.cpf;
    const password = this.form.value.password;
    if (user && password) {
      this.loaderService.show()
      this.auth.login(this.form.value).subscribe(result => {

        let user = { ...result.data, token: result.token };

        this.store.dispatch(login({ user }));
        this.loaderService.hide()
        this.messageService.showMessageMobile("Login realizado com sucesso!", "Sucesso!", 1000);

        if (user.termoAcesso === false) {
          this.router.navigateByUrl('/termo');
        } else if (this.urlDigitada) {
          this.router.navigateByUrl(this.urlDigitada)
        } else {
          // this.router.navigateByUrl('filas')
          this.router.navigateByUrl('informativo')
        }
      }, error => {
        this.loaderService.hide()
        localStorage.clear();
        this.error = error?.error?.error;
        //this.messageService.showMessageMobile(this.error,"Opss!", 3000);
        //console.log('error', error.error.error)

      })
    }

  }
}
