import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, shareReplay } from 'rxjs/operators';
import * as  APPUtils from '../../core/utils/app.utils';
import { MessageService } from './message.service';
import { AbstractControl, Validators } from '@angular/forms';
import * as dayjs from 'dayjs';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  URLBASE = APPUtils.URLBASE;

  constructor(private http: HttpClient, private messageService: MessageService) { }

  canViewCardapio() {
    return this.http.get(`${this.URLBASE}/api/pacientes/canViewCardapio`)
      .pipe(
        map((res: any) => res['data']['data']),
        //tap(console.log),
        shareReplay()
      );
  }  
  getDataAPI() {
    return this.http.get(`${this.URLBASE}/api/utils/getDataAPI`)
      .pipe(
        map((res: any) => res['data']['data']),
        //tap(console.log),
        shareReplay()
      );
  }
  getConfigData() {
    return this.http.get(`${this.URLBASE}/api/configData`)
      .pipe(
        map((res: any) => res['data']['data']),
        //tap(console.log),
        shareReplay()
      );
  }

  static sortAscById(c1: any, c2: any) {

    const compare = parseInt(c1.id) - parseInt(c2.id);

    if (compare > 0) {
      return 1;
    }
    else if (compare < 0) {
      return -1;
    }
    else return compare + 1;

  }

  /**
  * Etapas de Licenças do Paciente
  */
  public static getAllStatusInternacaoAcesso = () => {
    return [
      { value: 'liberado', label: "Liberado" },
      { value: 'entrou', label: "Entrou" },
      { value: 'saiu', label: "Saiu" }
    ]
  };

  /**Valida CPF */
  verificaCPF(strCPF: any) {
    let tituloMensagem = 'Validação de CPF';
    let messageValida = `CPF: ${strCPF} é Válido!`;
    let messageInvalida = `CPF: ${strCPF} é Inválido!`;
    var Soma;
    var Resto;
    Soma = 0;
    if (strCPF == "00000000000") {
      this.messageService.showMessageMobile(tituloMensagem, messageInvalida, 2000);
      return;
    }

    for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11)) Resto = 0;
    if (Resto != parseInt(strCPF.substring(9, 10))) {
      this.messageService.showMessageMobile(tituloMensagem, messageInvalida, 2000);
      return false;
    }

    Soma = 0;
    for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11)) Resto = 0;
    if (Resto != parseInt(strCPF.substring(10, 11))) {
      this.messageService.showMessageMobile(tituloMensagem, messageInvalida, 2000);
      return false;
    }
    this.messageService.showMessageMobile(tituloMensagem, messageValida, 2000)
    return true;
  }
  /**
   Valida se o CPF é valido. Deve-se ser informado o cpf sem máscara.
   */
  static isValidCpf() {
    return (control: AbstractControl): Validators => {
      const cpf = control.value;
      if (cpf) {
        let numbers, digits, sum, i, result, equalDigits;
        equalDigits = 1;
        if (cpf.length < 11) {
          return false;
        }

        for (i = 0; i < cpf.length - 1; i++) {
          if (cpf.charAt(i) !== cpf.charAt(i + 1)) {
            equalDigits = 0;
            break;
          }
        }

        if (!equalDigits) {
          numbers = cpf.substring(0, 9);
          digits = cpf.substring(9);
          sum = 0;
          for (i = 10; i > 1; i--) {
            sum += numbers.charAt(10 - i) * i;
          }

          result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

          if (result !== Number(digits.charAt(0))) {
            return { cpfNotValid: true };
          }
          numbers = cpf.substring(0, 10);
          sum = 0;

          for (i = 11; i > 1; i--) {
            sum += numbers.charAt(11 - i) * i;
          }
          result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

          if (result !== Number(digits.charAt(1))) {
            return { cpfNotValid: true };
          }
          return false;
        } else {
          return { cpfNotValid: true };
        }
      }
      return false;
    };
  }
  public static removeFieldSpaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, '').length) {
      control.setValue('');
    }
    return null;
  }

  /**
 * Retrona apenas a data DD/MM/YYYY
 * @param timestamp
 * @returns
 */
  static getDataOnly(timestamp: any) {
    return dayjs(timestamp).format('MM/DD/YYYY');
  }

  public static getCk(ck: any) {
    const hoje = new Date();
    const dia = hoje.getDate();
    const mes = hoje.getMonth() + 1;
    const ano = hoje.getFullYear();

    const calculo = 7 * ((dia * 8) + (mes * 3) + ano);
    //console.log('Calculo', calculo)
    return ck == calculo ? true : false;
  }
}
