
<div class="site-component">
        
  <div class="container">
      <div class="card">
          <div class="body">
              <div class="form_column">              
                <img class="logo-clinica" [src]="logoCrmExterno" alt="Crm-externo"> 
                  <h4><strong>Login</strong></h4>
                  <form novalidate [formGroup]="form" #formulario="ngForm" (ngSubmit)="onSubmit(formulario)">      
                      <p>
                        <mat-form-field appearance="outline">
                          <mat-label>Usuário</mat-label>
                          <input type="text"                      
                          id="cpf"
                          name="cpf"                      
                          formControlName="cpf"
                          pattern="[0-9]*" 
                          mask="000.000.000-00"
                          required matInput>                     
                        </mat-form-field>
                      </p>
                      <p>
                        <mat-form-field appearance="outline">
                          <mat-label>Senha</mat-label>
                          <input type="password"                      
                          id="password"
                          name="password"                      
                          formControlName="password"
                          required matInput>                     
                        </mat-form-field>
                      </p>
                                           
                      <div  class="class-button" >                         
                        <button class="button" mat-flat-button color="primary"  type="submit" style="width: 90%; height: 50px; font-size: 22px;"
                        (click)="login()"
                        [disabled]="!form.valid">
                        <span *ngIf="!showLoading else elseLogin">Entrar</span>
                            <ng-template #elseLogin>
                                <i class="fa fa-cog fa-spin"></i>
                            </ng-template>
                        </button>
                      </div>
                  </form>                        
                  <div *ngIf="error"  class="erro">
                    {{error}}                    
                  </div>
                </div>
          </div>
      </div>
  </div>
  <div class="footer">
    &copy; Todos os direitos reservados!
  </div> 
</div>
 
