import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { Pesquisa } from 'src/app/core/model/pesquisa';

import * as APPUtils from '../../core/utils/app.utils';


@Injectable({
  providedIn: 'root'
})
export class PesquisaService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getPesquisaDisponivel(): Observable<Pesquisa[]> {
    return this.httpClient.get<any[]>(`${APPUtils.URLBASE}/api/pesquisaPerguntas`);
  }

  create(data: any): Observable<any> {
    return this.httpClient.post(`${APPUtils.URLBASE}/api/pesquisaRespostas`, data)
      // .pipe(shareReplay());
  }

  mostrarMenuPesquisa(cpf: any): Observable<any> {
    return this.httpClient.get(`${APPUtils.URLBASE}/api/pesquisaRespostas/enableMenuPesquisa?cpf=${cpf}`);
  }

}
