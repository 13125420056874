import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map, shareReplay, take, tap } from "rxjs/operators";
import * as  APPUtils from '../../core/utils/app.utils';
import { User } from "src/app/core/model/user";


@Injectable({
    providedIn: 'root'
})
export class UserHttpService {

    urlBase = APPUtils.URLBASE;
    constructor(private httpClient: HttpClient) {

    }

    findAllUsers(): Observable<User[]> {

        return this.httpClient.get(`${this.urlBase}/api/pacientes?&ativo=true`)
            .pipe(
                map((res: any) => res['data']['data']),
                shareReplay()
            );
    }

    searchUsers(field: any, text: any, ativo: any): Observable<User[]> {
        //const search = `{"${field}" : { "$in": "${text}"}}`;
        const search = `{"ativo": "${ativo}" ,"${field}" : { "$regex" : "${text}", "$options": "i" }}`;
        /*       return  createHttpObservable(`${this.urlBase}/api/pacientes/${search}/search`)
                      .pipe(
                          take(1),
                          map((res: any) => res['data']['data']),
                          shareReplay()
                      ); */
        return this.httpClient.get(`${this.urlBase}/api/pacientes/${search}/search`)
            .pipe(
                take(1),
                map((res: any) => res['data']['data']),
                //tap(console.log),
                shareReplay()
            );
    }

    getById(userId: any): Observable<any> {
        return new Observable<any>(obs => {
            this.httpClient.get(`${APPUtils.URLBASE}/api/pacientes/${userId}`)
                .pipe(
                    map((res: any) => res['data']['data'])
                ).subscribe((result: any) => {

                    obs.next(result);
                    obs.complete();

                }, err => obs.error(err));
        });
    }

    create(user: any): Observable<User[]> {
        return new Observable<any>(obs => {
            this.httpClient.post(`${APPUtils.URLBASE}/api/pacientes/createOne`, user)
                .subscribe((result: any) => {

                    obs.next(result);
                    obs.complete();

                }, err => {
                    obs.error(err)
                });
        });
    }

    editar(user: any): Observable<any> {
        return new Observable<any>(obs => {
            this.httpClient.put(`${APPUtils.URLBASE}/api/pacientes/${user._id}`, user)
                .pipe(
                    map((res: any) => res['data'])
                ).subscribe((result: any) => {
                    obs.next(result);
                    obs.complete();

                }, err => obs.error(err));
        });
    }


    resetPassword(user: any, id: any): Observable<any> {
        return new Observable<any>(obs => {
            this.httpClient.put(`${APPUtils.URLBASE}/api/pacientes/${id}/resetPassword`, user,)
                .pipe(
                    map((res: any) => res['data'])
                ).subscribe((result: any) => {

                    obs.next(result);
                    obs.complete();

                }, err => obs.error(err));
        });
    }
    changePassword(user: any, id: any): Observable<any> {
        return new Observable<any>(obs => {
            this.httpClient.put(`${APPUtils.URLBASE}/api/pacientes/${id}/mudarSenha`, user,)
                .pipe(
                    map((res: any) => res['data'])
                ).subscribe((result: any) => {

                    obs.next(result);
                    obs.complete();

                }, err => obs.error(err));
        });
    }

    /**Get Roles */
    findAllRoles(): Observable<User[]> {

        return this.httpClient.get(`${this.urlBase}/api/roles`)
            .pipe(
                map((res: any) => res['data']['data']),
                shareReplay()
            );
    }

}