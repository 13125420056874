import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthActions } from '../actions/action-types';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { tap } from 'rxjs/operators';
import { Storage } from 'src/app/core/services/storage.service';

@Injectable()
export class AuthEffects {



  login$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(AuthActions.login),
        tap(action => {
          //Storage.localSet('user',action.user);
        }
        )
      )
    ,
    { dispatch: false });

  logout$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(AuthActions.logout),
        tap(action => {
          Storage.clear();
          localStorage.setItem('logoutIndicator', 'true');
          window.location.reload();
          //this.router.navigateByUrl('/login');
          //setTimeout(() => {window.location.reload()},200);
          //setTimeout(() => {window.location.reload()},200);
        })
      )
    , { dispatch: false });


  constructor(private actions$: Actions,
    private authService: AuthService,
    private router: Router) {

  }

}
