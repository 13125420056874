<div class="home-panel" >
  <form [formGroup]="form" novalidate #formulario="ngForm" fxLayout="column" fxLayoutAlign="center center">    
      <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between  begin">
        <mat-radio-group fxFlex="100" formControlName="horario"  appearance="outline"
        aria-label="Select an Carro">
        <!-- <mat-label>Escolha o Horário</mat-label> -->
          <mat-radio-button [value]="'09:00'">09:00h <span>{{nove}} vagas</span> </mat-radio-button>
          <mat-radio-button  style="margin-left: 20px;" [value]="'14:00'">14:00h <span >{{quatorze}} vagas</span> </mat-radio-button>
        </mat-radio-group>
      </div>       
  </form>
</div>