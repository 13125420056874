
<div class="item-card-tab" *ngIf="opcoes.length > 0" fxLayout="column" fxFlex="100" fxLayoutAlign="space-between stretch">                                   
  <div mat-subheader style="margin-bottom: -10px;">
    <mat-icon mat-list-icon>{{iconTitle}}</mat-icon>
    <strong style="margin-left: 10px;">{{titulo}}</strong>
    <span style="margin-left: 10px;"> 
      <button *ngIf="dataRefeicao" mat-stroked-button color="warn"> {{getData(dataRefeicao)}} - {{loadHora()}} <!-- {{dataRefecao | date: 'shortDate'}} --></button>
    </span>
  </div> 
  <mat-list class="cardapio-list" *ngFor="let opcao of opcoes; let i=index;">
    <mat-list-item class="list-horizontal">
      <mat-icon mat-list-icon>{{iconList}}</mat-icon>                                  
      <div [matBadge]="opcao?.qtde" style="padding-right: 20px;">
        {{opcao?.titulo ? opcao?.titulo : opcao?.desc}} 
      </div>
    </mat-list-item>
  </mat-list>                          
</div>