import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';



@Component({
    selector: 'dialog-warning',
    templateUrl: './dialog-warning.component.html',
    styleUrls: ['./dialog-warning.component.scss']
})
export class DialogWarningComponent {

   constructor(
      public dialogRef: MatDialogRef<DialogWarningComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any) {}
  
    onNoClick(): void {
      this.dialogRef.close();
    }
}
