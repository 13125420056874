import { environment } from "src/environments/environment";

/**
 * Imagens usadas no aplicativo
 */
export const default_food: string = `${location.origin}/assets/img/default-food.png`;
export const bg_ico_clinica: string = `${location.origin}/assets/img/bg_ico_clinica.png`;
export const photoPefil: string = `${location.origin}/assets/img/default-perfil.png`;
export const logo: string = `${location.origin}/assets/img/brand_green.png`;
export const logoHeader: string = `${location.origin}/assets/img/logo.png`;
export const filaCard: string = `${location.origin}/assets/img/queue.png`;
//export const filaCard: string = `${location.origin}/assets/cards/fila.png`;
export const agendaCard: string = `${location.origin}/assets/cards/agenda.png`;
export const userCard: string = `${location.origin}/assets/cards/user.png`;
export const cardapioCard: string = `${location.origin}/assets/cards/agenda.png`;
export const bgpsicologo: string = `${location.origin}/assets/cards/psicologia.png`;
export const informativoCartilhaUsuario: string = `${location.origin}/assets/cards/cartilhaUsuarios.webp`;
export const informativoSegurancaPaciente: string = `${location.origin}/assets/cards/segurancaPaciente.webp`;
export const informativoOrientacaoManutencao: string = `${location.origin}/assets/cards/orientacaoManutencao.webp`;
export const informativoCartilhaPaciente: string = `${location.origin}/assets/cards/cartilha-paciente.webp`;
export const informativoCartilhaCuidador: string = `${location.origin}/assets/cards/cartilha-cuidador.webp`;
export const informativoSuaOpniao: string = `${location.origin}/assets/cards/sua-opniao.webp`;


/**Chaves usadas na plicação */
export const DATA_KEY_ENCRYPET: string = "yzuS/dijOwFp0U+kBQkF+dYSzKma4+xLKpMJnlFAvRUi0pXP7m6Yhj8JlM8q/Opt7BV8CQvPvTclTuIQL1/MiQ==";

/** Chave do swPush */
export const VAPID_PUBLIC_KEY: string = 'BCH5VWMqOjhi2TlzgeFEUQYtQ1Bhm5dgkBmDEpmaNC-5g5bz3hFCdqhUYLj1rWhBDuAuMdWYRHnpPPludrEwmRs';

/**URL's da API */
export const URLBASE = environment.API;
// export const IMAGEPESO = `${URLBASE}/api/public/assets/img/peso`;
export const IMAGEPESO = `${location.origin}/assets/img/peso`;


