import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { EnqueteService } from 'src/app/enquete/services/enquete.service';

import { MessageService } from '../services/message.service';
import { Storage } from '../services/storage.service';


@Injectable({
  providedIn: 'root'
})
export class EnqueteGuard implements CanActivateChild, CanLoad {

  constructor(
    private enqueteService: EnqueteService,
    private messageService: MessageService,
    private router: Router,
  ) {
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
      let user = Storage.get('user');
      return this.enqueteService.mostrarMenuEnquete(user._id)
      .pipe(
        map((res: any) => res['data']['data']),
        tap(data => {
          // console.log(data)
        if (!data)
          this.messageService.showMessageMobile('Você não tem acesso a essa rota, favor falar com o suporte', 'X', 5000)
          // this.router.navigateByUrl("/filas");
        })
      )
  }
  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {

    return true;
  }

}
