import {Component, Input, OnInit} from '@angular/core';
import * as dayjs from 'dayjs';


@Component({
    selector: 'item-card-tab',
    templateUrl: './item-card-tab.component.html',
    styleUrls: ['./item-card-tab.component.scss']
})
export class ItemCardTabComponent implements OnInit {
  
  @Input() opcoes: any[] = [];   
  @Input() dataRefeicao: string =  '';   
  @Input() hora: string =  '0';   
  @Input() refeicao: number =  0;   
  @Input() titulo: string =  'Itens do Almoço:';   
  @Input() iconTitle: string =  'check_circle';   
  @Input() iconList: string =  'check_circle';   

    constructor( ) { }

    ngOnInit() {  }

    getData(dataRefeicao:any){      
      return dayjs(dataRefeicao).format("DD/MM/YYYY")
    }

    loadHora() {
      return getListaHoraRefeicao(this.refeicao)[parseInt(this.hora)]
    }

}

const getListaHoraRefeicao = (key: any) => {
  switch (key) {
    case 1:
      return ["","07h15","07h31","07h46","08h01","08h16","08h31"]   
    case 2:
        return ["","11h15","11h31","11h46","12h00 ","12h36","13h01"]    
    case 3:
        return ["","14h30","14h46","15h01","15h16","15h31"]    
    case 4:
        return ["","17h15","17h31","17h46","19h01 ","19h16","19h31"]         
    default:
      return []
  }
}