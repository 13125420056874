import { Component, OnInit, Input, ChangeDetectorRef, ChangeDetectionStrategy, OnChanges, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';

@Component({
  selector: 'app-card-acompanhar-peso',
  templateUrl: './card-acompanhar-peso.component.html',
  styleUrls: ['./card-acompanhar-peso.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardAcompanharPesoComponent implements OnInit, OnChanges {

  @Input() label: string = "";
  @Input() total: number = 0;
  @Input() percentage: string = "0";
  @Input() namePaciente: string = "";
  @Input() data = [];
  @Input() categories = [];

  Highcharts = Highcharts;
  chartOptions: any = {};

  constructor(private cd: ChangeDetectorRef) { }

  ngOnInit() {this.loadChart();}

  ngOnChanges(changes: SimpleChanges) {
    // changes.prop contains the old and the new value...
    //console.log(' changes.prop ', changes )
    this.loadChart();
  }
  loadChart(){    
    this.cd.markForCheck();
    this.chartOptions = {
      chart: {
        type: 'spline',
   /*      
   */
  /*  marginLeft: 40, // Keep all charts left aligned
  spacingTop: 20,
  spacingBottom: 20,
  */
 //margin: [2, 2, 2, 2], 
          borderWidth: 0,
          backgroundColor: null,
         height: 200, 
      },
      name: {
        name: 'Totais'
      },
      title: {
        text: null
      },
      subtitle: {
        text: null
      },
   /*    tooltip: {
        split: true,
        outside: true
      }, */
      tooltip: {
        crosshairs: true,
        shared: true
      },
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false,
      },
      xAxis: {
        //type: 'datetime',
        categories: this.categories,
      /*   labels: {
          style: {
            color: 'red'
          }
        }, */
        title: {
          text: null
        },
        startOnTick: false,
        endOnTick: false,
        tickOptions: [] ,
        dataLabels: {
          enabled: true
      },
   
      },
      yAxis: {        
        labels: {
          enabled: false,
        },
        title: {
          text: null
        },
        startOnTick: false,
        endOnTick: false,
        tickOptions: [],      
      },
      plotOptions: {
        series: {
            dataLabels: {
                enabled: true
            }
        },
        line: {
          dataLabels: {
              enabled: true
          },
          enableMouseTracking: false
      }
    },
      series: [{
        data: this.data,
        colorByPoint: true,
        gridLineColor:'#6c9d26',
        lineColor: '#6c9d26',
        maxColor: '#6c9d26',
        minColor: '#c2d0b0',
        name: '',
        marker: {
          symbol: 'square'
        },
     /*    dataLabels: {
          enabled: true
      } */
      },
       ],           

    };
    
    

    HC_exporting(Highcharts);

    setTimeout(() => {      
      window.dispatchEvent(
        new Event('resize')
      );
    }, 300);
  }
  
}