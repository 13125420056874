import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { CardapioHttpService } from 'src/app/cardapios/services/cardapio-http.service';



@Component({
    selector: 'dialog-meus-pedidos',
    templateUrl: './dialog-meus-pedidos.component.html',
    styleUrls: ['./dialog-meus-pedidos.component.scss']
})
export class DialogMeusPedidosComponent {

  cardapiosPaciente$: Observable<any> | undefined;  

   constructor(
    private cardapioHttpService: CardapioHttpService, 
      public dialogRef: MatDialogRef<DialogMeusPedidosComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any) {
        
        this.cardapiosPaciente$ = this.cardapioHttpService.getAllCardapioPacienteByCPF(data.cpf);  

      }
  
    onNoClick(): void {
      this.dialogRef.close();
    }

/*     loadByCpf(cpf: any) {
     
      
  } */
    
}
