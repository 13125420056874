import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AuthService } from '../../auth/services/auth.service';
import { Router } from '@angular/router';
import { MessageService } from 'src/app/core/services/message.service';
import { EncriptService } from '../utils/encripts-data';
import { Storage } from './storage.service';
import { AppState } from 'src/app/reducers';
import { Store } from '@ngrx/store';
import { logout } from 'src/app/auth/ngrx/actions/auth.actions';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {
  private pendingRequests = new Map<string, boolean>();

  constructor(private router: Router,
              private authService: AuthService,
              private store: Store<AppState>,
              private messageService: MessageService,
              ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const requestKey = this.generateRequestKey(request);
    // Verifica se já existe uma requisição idêntica em andamento
    if (this.pendingRequests.has(requestKey)) {
      console.warn(`Requisição duplicada detectada: ${requestKey}`);
      //return of(); // Ignora a requisição duplicada
    }
    
    // Marca a requisição como pendente
    this.pendingRequests.set(requestKey, true);
    
    let token = this.authService.getToken(); 
    
    if (token) {      
      const HEADERS_TOKEN = new HttpHeaders({
            Authorization: 'Bearer ' + token            
         });
      request = request.clone({ headers: HEADERS_TOKEN });     
    }


    return next.handle(request).pipe(
        map((event: any) => {
          if (event instanceof HttpResponse) { 
            this.pendingRequests.delete(requestKey);         
            if (event.body) {                
                return new HttpResponse({ 
                  headers: event['headers'], 
                  status: event['status'], 
                  statusText: event['statusText'], 
                  url: event['url'] || undefined, 
                  body:  EncriptService.decryptData(event['body'])});
                //return new HttpResponse({...event, body:  EncriptService.decryptData(event['body'])});
                
            }             
        } else {                
            return  event;
        } 
        }),

        catchError((error => this.handleError(error, requestKey)
      ))
    );
  }


  private handleError(error: Response | any, requestKey: any) {
    this.pendingRequests.delete(requestKey);
    let _msg = this.checkStatus(error);      
      let errObj = {
          status: error.status ? error.status : 0,
          statusText: error.statusText ? error.statusText : '',
          texto: _msg,
          erro: error
      }
      return throwError(errObj);   
    }

    checkStatus(res: Response | any) {
      if (res instanceof HttpErrorResponse) {
      }
      if (res.status || res.status == 0) {          
          
          let _msg = res?.error?.error;          
          console.log(res.status)
          switch (res.status) {                
              case 419:
                  _msg = _msg || 'A sua sessão expirou! Refaça o login';  
                  localStorage.clear();
                  this.messageService.showMessageMobile(_msg,"Sessão Expirou!", 5000);
                  this.router.navigate(['/login']);                                    
                  break;
                
             /*  case 0:
                  _msg = _msg || 'A sua sessão expirou!';  
                  localStorage.clear();
                  this.messageService.showMessageMobile(_msg,"Sessão Expirou!", 5000);
                  this.router.navigate(['/login']);                                    
                  break;  */
                  
              case 401:                  
                  _msg = _msg || 'Você não está autorizado.';
                  this.messageService.showMessageMobile(_msg,"Não Autorizado!", 5000);         
                  break;

              case 400:
                  //console.log(res)
                  _msg = _msg || 'Um erro inesperado ocorreu ao executar a ação.';
                  this.messageService.showMessageMobile(_msg,"Falha indefinida!", 5000);
                  break;

              case 403:
                  _msg = _msg || 'Você não tem permissão para acessar este recurso';
                  this.messageService.showMessageMobile(_msg,"Acesso Negado!", 5000);
                  break;

              case 404:
                  _msg = _msg || 'Este recurso não existe';
                  this.messageService.showMessageMobile(_msg,"Não encontrado!", 5000);
                  break;

              case 500:
                  _msg = _msg || 'Ocorreu um erro interno no servidor. Tente novamente em alguns minutos';
                  this.messageService.showMessageMobile(_msg,"Erro no servidor!", 5000);
                  break;
              case 426:                
                  _msg = _msg || 'Usuário está bloqueado, favor entrar em contato com a recepção!';
                  this.messageService.showMessageMobile(_msg,"Não autorizado!", 5000);
                  break;
              case 429:                
                  _msg = _msg;
                  this.messageService.showMessageMobile(_msg,"Usuário Bloqueado!", 5000);                  
                  this.store.dispatch(logout());
                  break;

              default:
                  _msg = _msg || res?.console?.error?.error;                  
          }

          return _msg;

      }

      return '';
  }

  private generateRequestKey(req: HttpRequest<any>): string {
    const { method, urlWithParams } = req;
    return `${method} ${urlWithParams}`;
  }

}