
import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import { LoginPageComponent } from './login-page/login-page.component';
import { TermoAcessoComponent } from './termo-acesso/termo-acesso.component';

const routes: Routes = [
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: 'login', component: LoginPageComponent },   
    { path: 'termo', component: TermoAcessoComponent },
    ];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AuthRoutingModule { }