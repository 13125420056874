import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AddGlobalError } from '../ngrx/global-error/actions/cliente.actions';
import { ErrorGlobalState } from '../ngrx/global-error/state/state';
import {Store} from '@ngrx/store';

@Injectable({
    providedIn: 'root'
  })
export class ErrorInterceptor implements HttpInterceptor {
    constructor( private store: Store<ErrorGlobalState>) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    //console.log('Event ', event);
                    this.store.dispatch(new AddGlobalError(null));
                  }
                  return event;//this.handleSuccess(event);
                }),
            catchError(err => {
            if (err.status === 504) {
                // auto logout if 401 response returned from api                
                //location.reload(true);
                //console.log('err.status 504',err)
                this.store.dispatch(new AddGlobalError(err));
            }            
            return throwError(err);
        }))
    }
}