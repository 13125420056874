<div class="site-component">

  <div class="container">
    <div class="card">
      <div class="body">
        <div class="form_column">

          <h4><strong>Termo de Acesso</strong></h4>

          <mat-card-content id="text-termo">
            <div style="margin: 15px 20px; text-align: justify;">
              <!-- <h4>Termos de Uso</h4><br> -->
              <p>Na Clínica da Obesidade, privacidade e segurança são prioridades e nos comprometemos com a
                transparência do tratamento de dados pessoais dos nossos pacientes. Por isso, esta presente Política de
                Privacidade estabelece como é feita a coleta, uso e transferência de informações de pacientes ou outras
                pessoas que acessam ou usam nosso aplicativo.</p>

              <p>Ao utilizar nossos serviços, você entende que coletaremos e usaremos suas informações pessoais nas
                formas descritas nesta Política, sob as normas de Proteção de Dados (LGPD, Lei Federal 13.709/2018), das
                disposições consumeristas da Lei Federal 8078/1990 e as demais normas do ordenamento jurídico brasileiro
                aplicáveis.</p>

              <p>Dessa forma, a Clínica da Obesidade inscrita no CNPJ/MF sob o nº 09284172/0001-09 no papel de
                Controladora de Dados, obriga-se ao disposto na presente Política de Privacidade.</p>


              <h3><strong>Quais dados coletamos sobre você e para qual finalidade?</strong></h3>


              <p>Nosso site coleta e utiliza alguns dados pessoais seus, de forma a viabilizar a prestação de serviços e
                aprimorar a experiência de uso.</p>

              <h3><strong>1.1. Dados pessoais fornecidos pelo titular</strong></h3>

              <p>CPF para fazer o login na aplicação</p>


              <h3><strong>2.1. Consentimento</strong></h3>

              <p>É a partir do seu consentimento que tratamos os seus dados pessoais. O consentimento é a manifestação
                livre, informada e inequívoca pela qual você autoriza a Clínica da Obesidade a tratar seus dados.</p>

              <p>Assim, em consonância com a Lei Geral de Proteção de Dados, seus dados só serão coletados, tratados e
                armazenados mediante prévio e expresso consentimento. </p>

              <p>O seu consentimento será obtido de forma específica para cada finalidade acima descrita, evidenciando o
                compromisso de transparência e boa-fé da Clínica da Obesidade para com seus pacientes, seguindo as
                regulações legislativas pertinentes.</p>

              <p>Ao utilizar os serviços da Clínica da Obesidade e fornecer seus dados pessoais, você está ciente e
                consentindo com as disposições desta Política de Privacidade, além de conhecer seus direitos e como
                exercê-los.</p>

              <p>A qualquer tempo e sem nenhum custo, você poderá revogar seu consentimento.
              <p>

              <p>É importante destacar que a revogação do consentimento para o tratamento dos dados pode implicar a
                impossibilidade da performance adequada de alguma funcionalidade do aplicativo que dependa da operação.
                Tais consequências serão informadas previamente.
              <p>

              <h3><strong>3. Quais são os seus direitos?</strong></h3>

              <p> A Clínica da Obesidade assegura a seus pacientes seus direitos de titular previstos no artigo 18 da
                Lei Geral de Proteção de Dados. Dessa forma, você pode, de maneira gratuita e a qualquer tempo:
              <p>

              <p>
                - Confirmar a existência de tratamento de dados, de maneira simplificada ou em formato claro e completo.
                <br>
                - Acessar seus dados, podendo solicitá-los em uma cópia legível sob forma impressa ou por meio
                eletrônico, seguro e idôneo. <br>
                - Corrigir seus dados, ao solicitar a edição, correção ou atualização destes. <br>
                - Limitar seus dados quando desnecessários, excessivos ou tratados em desconformidade com a legislação
                através da anonimização, bloqueio ou eliminação. <br>
                - Solicitar a portabilidade de seus dados, através de um relatório de dados cadastrais que a Clínica da
                Obesidade trata a seu respeito. <br>
                - Eliminar seus dados tratados a partir de seu consentimento, exceto nos casos previstos em lei. <br>
                - Revogar seu consentimento, desautorizando o tratamento de seus dados. <br>
                - Informar-se sobre a possibilidade de não fornecer seu consentimento e sobre as consequências da
                negativa.
              <p>
              <h3><strong>4. Como você pode exercer seus direitos de titular?</strong></h3>

              <p>Para exercer seus direitos de titular, você deve entrar em contato com a Clínica da Obesidade através
                dos seguintes meios disponíveis:</p>
              <p><strong>E-mail:</strong> ti@clinicadaobesidade.com.br</p>
              <p>De forma a garantir a sua correta identificação como titular dos dados pessoais objeto da solicitação,
                é possível que solicitemos documentos ou demais comprovações que possam comprovar sua identidade. Nessa
                hipótese, você será informado previamente.</p>

              <h3><strong>5. Como e por quanto tempo seus dados serão armazenados?</strong></h3>
              <p>Seus dados pessoais coletados pela Clínica da Obesidade serão utilizados e armazenados durante o tempo
                necessário para a prestação do serviço ou para que as finalidades elencadas na presente Política de
                Privacidade sejam atingidas, considerando os direitos dos titulares dos dados e dos controladores.</p>
              <p>De modo geral, seus dados serão mantidos enquanto a relação contratual entre você e a Clínica da
                Obesidade perdurar. Findado o período de armazenamento dos dados pessoais, estes serão excluídos de
                nossas bases de dados ou anonimizados, ressalvadas as hipóteses legalmente previstas no artigo 16 lei
                geral de proteção de dados, a saber:</p>
              <p>
                I – cumprimento de obrigação legal ou regulatória pelo controlador;<br>
                II – estudo por órgão de pesquisa, garantida, sempre que possível, a anonimização dos dados
                pessoais;<br>
                III – transferência a terceiro, desde que respeitados os requisitos de tratamento de dados dispostos
                nesta Lei; ou<br>
                IV – uso exclusivo do controlador, vedado seu acesso por terceiro, e desde que anonimizados os
                dados.<br>
              </p>
              <p>
                Isto é, informações pessoais sobre você que sejam imprescindíveis para o cumprimento de determinações
                legais, judiciais e administrativas e/ou para o exercício do direito de defesa em processos judiciais e
                administrativos serão mantidas, a despeito da exclusão dos demais dados.
              </p>
              <p>
                O armazenamento de dados coletados pela Clínica da Obesidade reflete o nosso compromisso com a segurança
                e privacidade dos seus dados. Empregamos medidas e soluções técnicas de proteção aptas a garantir a
                confidencialidade, integridade e inviolabilidade dos seus dados. Além disso, também contamos com medidas
                de segurança apropriadas aos riscos e com controle de acesso às informações armazenadas.
              </p>
              <h3><strong>6. O que fazemos para manter seus dados seguros?</strong></h3>
              <p>
                Para mantermos suas informações pessoais seguras, usamos ferramentas físicas, eletrônicas e gerenciais
                orientadas para a proteção da sua privacidade.
              </p>
              <p>
                Aplicamos essas ferramentas levando em consideração a natureza dos dados pessoais coletados, o contexto
                e a finalidade do tratamento e os riscos que eventuais violações gerariam para os direitos e liberdades
                do titular dos dados coletados e tratados.
              </p>
              <p>
                Entre as medidas que adotamos, destacamos as seguintes:
              </p>
              <p>
                - Apenas pessoas autorizadas têm acesso a seus dados pessoais<br>
                - O acesso a seus dados pessoais é feito somente após o compromisso de confidencialidade<br>
                - Seus dados pessoais são armazenados em ambiente seguro e idôneo.
              </p>
              <p>
                A Clínica da Obesidade se compromete a adotar as melhores posturas para evitar incidentes de segurança.
                Contudo, é necessário destacar que nenhuma página virtual é inteiramente segura e livre de riscos. É
                possível que, apesar de todos os nossos protocolos de segurança, problemas de culpa exclusivamente de
                terceiros ocorram, como ataques cibernéticos de hackers, ou também em decorrência da negligência ou
                imprudência do próprio usuário/cliente.
              </p>
              <p>
                Em caso de incidentes de segurança que possa gerar risco ou dano relevante para você ou qualquer um de
                nossos usuários/clientes, comunicaremos aos afetados e a Autoridade Nacional de Proteção de Dados sobre
                o ocorrido, em consonância com as disposições da Lei Geral de Proteção de Dados.
              </p>
              <h3><strong>7. Com quem seus dados podem ser compartilhados?</strong></h3>
              <p>
                Tendo em vista a preservação de sua privacidade, a Clínica da Obesidade não compartilhará seus dados
                pessoais com nenhum terceiro não autorizado.
              </p>
              <p>
                A hipóteses em que seus dados poderão ser compartilhados, são:<br />

                - I – Determinação legal, requerimento, requisição ou ordem judicial, com autoridades judiciais,
                administrativas ou governamentais competentes.<br>

                - II – Caso de movimentações societárias, como fusão, aquisição e incorporação, de forma automática<br>

                - III – Proteção dos direitos da Clínica da Obesidade em qualquer tipo de conflito, inclusive os de teor
                judicial.
              </p>
              <h3><strong>8. Alteração desta Política de Privacidade</strong></h3>
              <p>
                A atual versão da Política de Privacidade foi formulada e atualizada pela última vez em: 8 de abril de
                2021.
              </p>
              <p>
                Reservamos o direito de modificar essa Política de Privacidade a qualquer tempo, principalmente em
                função da adequação a eventuais alterações feitas em nosso site ou em âmbito legislativo. Recomendamos
                que você a revise com frequência.
              </p>
              <p>
                Eventuais alterações entrarão em vigor a partir de sua publicação em nosso site e sempre lhe
                notificaremos acerca das mudanças ocorridas.
              </p>
              <p>
                Ao utilizar nossos serviços e fornecer seus dados pessoais após tais modificações, você as consente.
              </p>
              <h3><strong>9. Responsabilidade</strong></h3>
              <p>
                A Clínica da Obesidade prevê a responsabilidade dos agentes que atuam nos processos de tratamento de
                dados, em conformidade com os artigos 42 ao 45 da Lei Geral de Proteção de Dados.
              </p>
              <p>
                Nos comprometemos em manter esta Política de Privacidade atualizada, observando suas disposições e
                zelando por seu cumprimento.
              </p>
              <p>
                Além disso, também assumimos o compromisso de buscar condições técnicas e organizativas seguramente
                aptas a proteger todo o processo de tratamento de dados.
              </p>
              <p>
                Caso a Autoridade Nacional de Proteção de Dados exija a adoção de providências em relação ao tratamento
                de dados realizado pela Clínica da Obesidade, comprometemo-nos a segui-las.
              </p>
              <h3><strong>9.1 Isenção de responsabilidade</strong></h3>
              <p>
                Conforme mencionado no Tópico 6, embora adotemos elevados padrões de segurança a fim de evitar
                incidentes, não há nenhuma página virtual inteiramente livre de riscos. Nesse sentido, a Clínica da
                Obesidade não se responsabiliza por:
              </p>
              <P>
                - I – Quaisquer consequências decorrentes da negligência, imprudência ou imperícia dos usuários em
                relação a seus dados individuais. Garantimos e nos responsabilizamos apenas pela segurança dos processos
                de tratamento de dados e do cumprimento das finalidades descritas no presente instrumento. <br>

                Destacamos que a responsabilidade em relação à confidencialidade dos dados de acesso é do usuário.<br>

                - II – Ações maliciosas de terceiros, como ataques de hackers, exceto se comprovada conduta culposa ou
                deliberada da Clínica da Obesidade.<br>

                Destacamos que em caso de incidentes de segurança que possam gerar risco ou dano relevante para você ou
                qualquer um de nossos usuários/clientes, comunicaremos aos afetados e a Autoridade Nacional de Proteção
                de Dados sobre o ocorrido e cumpriremos as providências necessárias. <br>

                - III – Inveracidade das informações inseridas pelo usuário/cliente nos registros necessários para a
                utilização dos serviços da Clínica da Obesidade; quaisquer consequências decorrentes de informações
                falsas ou inseridas de má-fé são de inteiramente responsabilidade do usuário/cliente.
              </P>
              <h3><strong>10. Contato</strong></h3>
              <p>
                Caso tenha dúvidas sobre esta Política de Privacidade ou sobre os dados pessoais que tratamos, você pode
                entrar em contato com o setor responsável, através dos seguintes canais:
              </p>
              <p>
                <strong>E-mail:</strong> ti@hospitaldaobesidade.com.br<br />
                <strong>Telefone:</strong> (71) 3500-6000
              </p>
            </div>
          </mat-card-content>
          <div>
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
              <div class="form-group">
                <section class="section-aceito-termo">
                  <mat-checkbox class="checkbox-termo" formControlName="termoAcesso" required>
                    Aceito os Termos
                  </mat-checkbox>
                </section>
              </div>
              <div>
                <button mat-flat-button color="primary" [disabled]="!form.valid"
                  style="width: 100%; height: 50px; font-size: 22px; margin-bottom: 25px;">Aceito</button>
              </div>
              <!-- <button type="submit" mat-flat-button color="primary" style="width: 90%; height: 50px; font-size: 22px; margin-bottom: 25px;"
                           [disabled]="!form.valid">Aceito</button>   -->
            </form>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>