import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserHttpService } from '../../../users/services/user-http.service';
import { MessageService } from 'src/app/core/services/message.service';
import { Storage } from 'src/app/core/services/storage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change-password-user',
  templateUrl: './change-password-user.component.html',
  styleUrls: ['./change-password-user.component.scss']
})
export class ChangePasswordUserComponent implements OnInit {

  form: UntypedFormGroup;
  user: any;

  senhaValida: boolean = false;
  
  onSubmit(form: any){ }
  //password, newpassword 
  constructor(
    private messageService: MessageService,
    private userHttpService: UserHttpService,
    public cd: ChangeDetectorRef,
    private fb:UntypedFormBuilder,
    private router: Router,
    public dialogRef: MatDialogRef<ChangePasswordUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
  ) {
        this.form = fb.group({
          passwordAtual: ['', [Validators.required]],     
          password: ['', [Validators.required]],     
          confirmPassword: ['', [Validators.required]]     
        }); 
        this.user = data['user'];            
  }

  ngOnInit(): void {
  }

  alterar() {
    let user: any =  this.form.value;         
        this.userHttpService.changePassword(user, this.user['_id']).subscribe(resultUser => {
          this.messageService.showMessageMobile("Senha alterada com Sucesso!", "Edição", 5000)   
         this.dialogRef.close();
       /*   Storage.clear();
         this.router.navigateByUrl('login'); */ 
        }, erro => {
          //console.log(erro);        
          }
        );
  
   }
   validarSenha() {
    this.senhaValida = this.form.value.password == this.form.value.confirmPassword;        
    setTimeout(() => this.cd.markForCheck());
}

 

}
