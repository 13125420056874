import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-horario-form',
  templateUrl: './horario-form.component.html',
  styleUrls: ['./horario-form.component.scss']
})
export class HorarioFormComponent implements OnInit {

  @Output() formChange = new EventEmitter();
  @Input() nove = 50 
  @Input() quatorze = 50 
  @Input() horaSelecionada: any


  form: UntypedFormGroup;
  showLoading: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
  ) {
    this.form = fb.group({
      horario: ['09:00', [Validators.required]]
    });
  }
  
  ngOnInit(): void {
    this.form.valueChanges.subscribe(val => {
      this.horaSelecionada = val['horario'] || '09:00'
      this.formChange.emit({data: val, form: this.form})              
    });
  }
  
}
