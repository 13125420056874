import {Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Agenda } from 'src/app/core/model/agenda';
import * as  APPUtils from '../../../core/utils/app.utils';

@Component({
    selector: 'cronograma-card',
    templateUrl: './cronograma-card.component.html',
    styleUrls: ['./cronograma-card.component.scss']
})
export class CronogramaCardComponent implements OnInit {

    @Input() cronograma: any;    
    @Input() periodo: any;    

    @Output()
    clienteChanged = new EventEmitter();

    warniningCard = APPUtils.agendaCard;

    constructor() {
    }

    ngOnInit() {            
    }
}
