import * as CryptoJS from "crypto-js";
import * as APPUtils from './app.utils';
import {Injectable} from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class EncriptService {

  
   static encryptData = (data: any) => {

      try {
        return CryptoJS.AES.encrypt(JSON.stringify(data), APPUtils.DATA_KEY_ENCRYPET).toString();
      } catch (e) {
        return e;
      }
    }
//JSON.parse(CryptoJS.AES.decrypt(data.body, APPUtils.DATA_KEY).toString(CryptoJS.enc.Utf8))
   static decryptData = (data: any) => {      
      if(data){
        try {
            const bytes = CryptoJS.AES.decrypt(data, APPUtils.DATA_KEY_ENCRYPET);
            if (bytes.toString()) {
                return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            }
            return data;
        } catch (e) {
          return e;
        }
      }
      return data
    
  };
}