import {Component, Inject} from '@angular/core';


import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
    selector: 'dialog-update',
    templateUrl: './dialog-update.component.html',
    styleUrls: ['./dialog-update.component.scss']
})
export class DialogUpdateComponent {

  constructor(
    public dialogRef: MatDialogRef<DialogUpdateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
  update() {
      this.dialogRef.close(true);
  }
}