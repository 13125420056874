import {createFeatureSelector, createSelector} from '@ngrx/store';
import { Storage } from 'src/app/core/services/storage.service';
import {AuthState} from '../reducers';

export const selectAuthState =
    createFeatureSelector<AuthState>("auth");

export const isLoggedIn = createSelector(
    selectAuthState,
    auth => {
        //console.log('auth',auth)
        return auth.user ? auth.user['termoAcesso'] : auth.user 
    }    
);
export const canViewCardapio = createSelector(
    selectAuthState,
    auth => {
        //console.log('auth',auth)
        return auth.user ? ['22953558500','04003644506', '03624466526', '86163544501', '07731780589', '06723767500', '00000000000','27688917549', '96157631520', '06268572521', '98303538500', '06442617572', '05700972581', '34565632802', '05168905226' ].includes(auth.user?.cpf) : false
    }    
);
export const isAdmin = createSelector(
    selectAuthState,
    auth => {        
        return (auth.user && ['ADMIN'].includes(auth.user['roleId']['name']) ) ? true : false
    }
);
export const isRecepcao = createSelector(
    selectAuthState,
    auth => {        
        return (auth.user && ['ADMIN','RECEPCAO'].includes(auth.user['roleId']['name'] )) ? true : false
    }
);
export const isPaciente = createSelector(
    selectAuthState,
    auth => {        
        return (auth.user && ['ADMIN','PACIENTE'].includes(auth.user['roleId']['name']) ) ? true : false
    }
);
export const isAvaliacao = createSelector(
    selectAuthState,
    auth => {        
        return (auth.user && ['ADMIN','PACIENTE','AVALIACAO','RECEPCAO'].includes(auth.user['roleId']['name'])) ? true : false
    }
);

//this.user['roleId']['name'] == 'Admin' ? true : false
export const getUser = createSelector(
    selectAuthState,
    auth =>  {
        let user = Storage.get('user') ? Storage.get('user'): null;
        if(user) user['firstName'] = user?.name.split(" ")[0];
        return user;
    } 
);


export const isLoggedOut = createSelector(
    isLoggedIn,
    loggedIn => !loggedIn
);