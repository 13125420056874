import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { SwPush, SwUpdate } from '@angular/service-worker';
import { select, Store } from '@ngrx/store';
import { map, take, tap } from 'rxjs/operators';
import { login, logout } from './auth/ngrx/actions/auth.actions';
import { isLoggedIn, isLoggedOut, isAdmin, isRecepcao, isPaciente, isAvaliacao, getUser, canViewCardapio } from './auth/ngrx/selectors/auth.selectors';
import * as  APPUtils from './core/utils/app.utils';
import { AppState } from './reducers';
import { MatDialog } from '@angular/material/dialog';
import { DialogUpdateComponent } from './shared/modals/dialog-update/dialog-update.component';
import { Observable, of } from 'rxjs';
import { Location } from '@angular/common';
import { ChangePasswordUserComponent } from './shared/modals/change-password-user/change-password-user.component';
import { Storage } from './core/services/storage.service';
import { User } from './core/model/user';
import { EnqueteService } from './enquete/services/enquete.service';
import { PesquisaService } from './pesquisa-satisfacao/service/pesquisa.service';
import { MatExpansionPanel } from '@angular/material/expansion';
import { UtilsService } from './core/services/utils.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @Output() toggleSideBarForMe: EventEmitter<any> = new EventEmitter();
  logoHeader = APPUtils.logoHeader;
  photoPerfil = APPUtils.photoPefil;
  dataHoje = new Date();
  user: User = Storage.get('user');
  loading = true;
  checkInterent: boolean = false;
  isLoggedIn$: Observable<boolean> | undefined;
  canViewCardapio$: Observable<boolean> | undefined;
  error$: Observable<any> | undefined;

  isLoggedOut$: Observable<boolean> | undefined;
  isAdmin$: Observable<boolean> | undefined;
  isRecepcao$: Observable<boolean> | undefined;
  isAvaliacao$: Observable<boolean> | undefined;
  isPaciente$: Observable<boolean> | undefined;
  getUser$: Observable<User> | undefined;
  canWiewCardapio$: Observable<User> | undefined;

  namePerfil = '';
  mostrarEnquete: boolean = false;
  mostrarPesquisa: boolean = false;

  panelOpenState = false;

  isMenuOpened = false; 

  constructor(
    public dialog: MatDialog,
    private location: Location,

    private swUpdate: SwUpdate,
    private enqueteService: EnqueteService,
    private pesquisaService: PesquisaService,
    private utilsService: UtilsService,
    private swPush: SwPush,
    private router: Router,
    private store: Store<AppState>) {
    //this.swPush.messages.subscribe(messsage => console.log("Message",messsage));
    this.swPush.notificationClicks.subscribe(({ action, notification }) => {
      window.open(notification['data']['url']);
    });

  }
  ngOnInit() {
    
    this.user = Storage.get('user');
    // if (!this.user['nivelAcesso']) this.user['nivelAcesso'] = 0
    this.namePerfil = this.user && this.user.name ? this.user?.name.split(" ")[0] : '';
    if (this.user) {
      this.store.dispatch(login({ user: this.user }));
      // this.enqueteOn();
      this.pesquisaOn();
      //this.canViewCardapio()
    }

    this.router.events/* .pipe(
      take(1)
      //filter((e): e is NavigationEnd => e instanceof NavigationEnd)
    ) */.subscribe((event: any) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.loading = true;
          break;
        }
        case event instanceof NavigationEnd: {
          this.router.navigated = false;
          this.isLoggedIn$ ? this.isLoggedIn$.pipe(take(1)).subscribe(logado => {
            //console.log('logado',event['url'])
            //if ((event['url'] == '/login' && logado)){
            if ((event['url'] == '/login' || event['url'] == '/') && logado) {
              this.router.navigateByUrl('filas');
            }
          }) : undefined;
          break;
        }
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loading = false;
          break;
        }
        default: {
          break;
        }
      }
    });

    //console.log('this.swUpdate.isEnabled', this.swUpdate)
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        this.openDialog();
      })
    }

    this.isLoggedIn$ = this.store
      .pipe(
        select(isLoggedIn)
      );
    this.isLoggedOut$ = this.store
      .pipe(
        select(isLoggedOut)
      );

    this.isAdmin$ = this.store
      .pipe(
        select(isAdmin)
      );

    this.isRecepcao$ = this.store
      .pipe(
        //tap(console.log),
        select(isRecepcao)
      );

    this.isPaciente$ = this.store
      .pipe(
        select(isPaciente)
      );

    this.isAvaliacao$ = this.store
      .pipe(
        select(isAvaliacao)
      );

    this.getUser$ = this.store
      .pipe(
        select(getUser)
      );
      
    this.canViewCardapio$ = this.store
      .pipe(
        select(canViewCardapio)
      );
  }

  toggleMenu(): void {
    this.isMenuOpened = !this.isMenuOpened; // Alterna entre abrir e fechar
  }

  logout() {
    this.store.dispatch(logout());
  }

  canViewCardapio() {
   this.canWiewCardapio$ = this.utilsService.canViewCardapio()
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogUpdateComponent, {
      width: '350px',
      data: { name: "Atualização disponível!" }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) window.location.reload();
    });
  }
  openDialogChangePassword(): void {
    this.user = Storage.get('user');
    const dialogRef = this.dialog.open(ChangePasswordUserComponent, {
      width: '250px',
      data: {
        titulo: "Mudar Senha",
        user: this.user
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) window.location.reload();
    });
  }

  enqueteOn() {
    this.enqueteService.mostrarMenuEnquete(this.user._id)
      .pipe(
        map((res: any) => res['data'])
      ).subscribe(result => {
        //console.log('SHOW', result)
        this.mostrarEnquete = result.data;
        if (this.mostrarEnquete == true) {
        }
      });
  }
  pesquisaOn() {
    this.pesquisaService.mostrarMenuPesquisa(this.user.cpf)
      .pipe(
        map((res: any) => res['data'])
      ).subscribe(result => {
        this.mostrarPesquisa = result.data;
      });
  }

  onNavigate() {
    const url = 'https://hospitaldaobesidade.com.br/wp-content/uploads/2023/10/guia-digital-orientacoes-para-manutencao-edicao-01.pdf';
    window.open(url, '_blank');
  }

  closeExpansionMenu(panel: MatExpansionPanel) {
    panel.close();
  }

  /*  therichpost$() {
     return merge<boolean>(
       fromEvent(window, 'offline').pipe(map(() => false)),
       fromEvent(window, 'online').pipe(map(() => true)),
       new Observable((sub: Observer<boolean>) => {
         sub.next(navigator.onLine);
         sub.complete();
       }));
     } */
}
